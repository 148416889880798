import React, { Component, Fragment } from 'react'
import { Grid, Row, Col, ControlLabel, Panel } from 'react-bootstrap'
import leadership from '../../model/Leadership.js'
import certification from '../../model/Certification.js'
import { runRules, notAllValid } from '../../libs/validation/validator.js'
import { fieldValidations } from './validations.js'
import {insuranceAgreement, 
  leadershipAgreement, 
  instructorTrainerAgreement, 
  divemasterAgreement, 
  assistantInstructorAgreement, 
  instructorAgreement } from '../EditLeadership/agreements.js'
import InsuranceEditor from './InsuranceEditor.js'
import InsuranceViewer from './InsuranceViewer.js'
import AcceptanceViewer from './AcceptanceViewer.js'
import AcceptanceEditor from './AcceptanceEditor.js'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import moment from 'moment'
import Leadership from '../../model/Leadership.js';

class EditLeadership extends Component {
  constructor(props){
    super(props)
    this.state = {
      month: '',
      day: '',
      year: '',
      validationErrors: {}
    }
    this.state.validationErrors = runRules(this.state, fieldValidations)
  }

  componentDidMount = () => {
    console.log('mounting')
    this.loadData()
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.leadershipRecordId !== this.props.leadershipRecordId){
      this.loadData()
    }
  }

  loadData = async () => {
    //get the leadership object associated with the passed in ID
    console.log('load data')
    console.log(this.props)
    var record = await leadership.findByRecordId(this.props.leadershipRecordId)
    console.log(record)
    //load the associated certification object
    var cert = await certification.findById(record.certificationId)
    var errors = runRules(record, fieldValidations)
    //update state
    this.setState({
      certification: cert,
      validationErrors: errors,
      ...record
    })
  }

  getErrorFor = (field) => {
    return this.state.validationErrors[field] || ""
  }

  handleChange = (event) => {
    let newState = update(this.state, {
      [event.target.id]: {$set: event.target.value}
    });
    newState.validationErrors = runRules(newState, fieldValidations);

    this.setState(newState);
  }

  acceptAgreement = async ()=> {
    var now = moment()
    var newLeadership = new Leadership(this.state)
    newLeadership.acceptAgreement = true
    newLeadership.acceptDate = now
    var res = newLeadership.save()
    this.setState({...res})
  }

  render(){
    return(
      <Fragment>
        {
          this.state.certification
          ?
          <Fragment>
          <Panel>
            <Panel.Heading>
              Leadership Information
            </Panel.Heading>
            <Panel.Body>
              <Grid fluid={true}>
                <Row><ControlLabel>{'Associated Certification: ' + this.state.certification.certName}</ControlLabel></Row>
                <Row><ControlLabel>{'Expiration Date: ' + moment(this.state.expirationDate).format('MM/DD/YYYY')}</ControlLabel></Row>
              </Grid>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Heading>
              <ControlLabel>{this.state.certification.certName + ' Agreement'}</ControlLabel>
            </Panel.Heading>
            <Panel.Body>
              <Grid fluid={true}>
                <Row>
                  <Col md={12}>
                    {
                      ( () => {
                        switch (this.state.certification.certName){
                          case 'DIVEMASTER': return divemasterAgreement
                          case 'ASSISTANT INSTRUCTOR': return assistantInstructorAgreement
                          case 'INSTRUCTOR': return instructorAgreement
                          case 'INSTRUCTOR TRAINER': return instructorTrainerAgreement 
                        }
                      }) ()
                    }
                  </Col>
                </Row>
              </Grid>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Heading>
              <ControlLabel>Leadership Agreement</ControlLabel>
            </Panel.Heading>
            <Panel.Body>
              <Grid fluid={true}>
                <Row>
                  <Col md={12}>
                    {leadershipAgreement}
                  </Col>
                </Row>
              </Grid>
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Heading>
              <ControlLabel>Insurance Information</ControlLabel>
            </Panel.Heading>
            <Panel.Body>
              {
                this.state.insuranceAgency === ''
                ?
                <InsuranceEditor handleChange={this.handleChange} getErrorFor={this.getErrorFor} {...this.state} />
                :
                <InsuranceViewer {...this.state} />
              }
            </Panel.Body>
          </Panel>
          {
            this.state.acceptAgreement
            ?
            <AcceptanceViewer {...this.state} />
            :
            <AcceptanceEditor acceptAgreement={this.acceptAgreement} valid={() => notAllValid()} />
          }
        </Fragment>
        :
        null
        }
      </Fragment>

    )
  }
}



EditLeadership.propTypes = {
  leadershipRecordId: PropTypes.string.isRequired
}
export default EditLeadership