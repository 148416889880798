import moment from 'moment';

export const columns = [
  {
    Header: 'Diver Name',
    id: 'diverFullName',
    accessor: (d) => d.diver.surname + ', ' + d.diver.givenName,
  },
  {
    Header: 'Diver ID Number',
    id: 'diverIdNumber',
    accessor: (d) => d.diver.diverIdNumber,
  },
  {
    Header: 'Certification',
    id: 'diverCertificationName',
    accessor: (d) => d.certification.certName,
  },
  {
    Header: 'Certification Date',
    id: 'certificationDateFormatted',
    accessor: (d) => moment(d.certification.certDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
  },
  {
    Header: 'Status',
    id: 'certificationStatus',
    accessor: (d) => d.certification.status,
  },
];
