//All data releating to a leadership certification
import { apiName } from '../config.js'
import { API } from 'aws-amplify'
import moment from 'moment'
import _ from 'lodash'

var Leadership = function(input){
    //load input
    this.data = input

    if(_.has(this.data, 'insuranceDocuments')){
        this.data.insuranceDocuments = []
    }

    Object.defineProperty(this, 'personId', {
        get: function(){
            return this.data.personId
        },
        set: function(value) {
            this.data.personId = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'leadershipRecordId', {
        get: function(){
            return this.data.leadershipRecordId
        },
        set: function(value) {
            this.data.leadershipRecordId = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'level', {
        get: function(){
            return this.data.level
        },
        set: function(value) {
            this.data.level = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'certificationId', {
        get: function(){
            return this.data.certificationId
        },
        set: function(value) {
            this.data.certificationId = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'expirationDate', {
        get: function(){
            return this.data.expirationDate
        },
        set: function(value) {
            this.data.expirationDate = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'acceptAgreement', {
        get: function(){
            return this.data.acceptAgreement
        },
        set: function(value) {
            this.data.acceptAgreement = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'acceptDate', {
        get: function(){
          return this.data.acceptDate
        },
        set: function(value) {
          this.data.acceptDate = value
        },
        enumerable: true
      })
      
    Object.defineProperty(this, 'insuranceAgency', {
        get: function(){
            return this.data.insuranceAgency
        },
        set: function(value) {
            this.data.insuranceAgency = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'insurancePolicyNumber', {
        get: function(){
            return this.data.insurancePolicyNumber
        },
        set: function(value) {
            this.data.insurancePolicyNumber = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'insuranceExpirationDate', {
        get: function(){
            return this.data.insuranceExpirationDate
        },
        set: function(value) {
            this.data.insuranceExpirationDate = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'insuranceDocuments', {
        get: function(){
            return this.data.insuranceDocuments
        },
        set: function(value) {
            this.data.insuranceDocuments = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'status', {
        get: function(){
            return this.data.status
        },
        set: function(value) {
            this.data.status = value
        },
        enumerable: true
    })

    Object.defineProperty(this, 'createdOn', {
        value: this.data.createdOn,
        writable: false,
        configurable: false,
        enumerable: true
    })

    Object.defineProperty(this, 'lastModified', {
        value: this.data.lastModified,
        writable: false,
        configurable: false,
        enumerable: true
    })

    Object.defineProperty(this, 'data',{
        enumerable: false
    })
}

Leadership.prototype.save = async function(){
    var data = this.data
    var path = '/leadership'
    var myInit = {
        body: {data}
    }
   // try {
        var response = await API.post(apiName, path, myInit)
        return new Leadership(response)
   // } catch (e) {
       // console.log(e)
     //   alert(e)
   // }
}

Leadership.findByRecordId = async function(leadershipRecordId){
    return await findById('/leadership/' + leadershipRecordId)
}

Leadership.findByPersonId = async function(personId){
    return await findById('/person/leadership/' + personId)
}

const findById = async function(path){
    try{
        var response = await API.get(apiName, path)
        if (response.err){
            throw(response.err)
        }
        var newLeadership = new Leadership(response)
        return newLeadership
    } catch(e) {
        alert(e)
    }
}
export default Leadership