import React, { Fragment } from 'react'
import { Grid, Row, Col, Panel, ControlLabel} from 'react-bootstrap'
import PersonPhotoViewer from './PersonPhotoViewer.js'

export default function PersonInfoPanel(props) {
  var {person, msg} = props
  var picture = props.showPicture || false
  if( person && person.personId)
  {
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>{msg}</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Grid fluid={true}>
            <Row>
              <Col md={6}>
                <Row><ControlLabel>{person.givenName + ' ' + person.surname}</ControlLabel></Row>
                <Row><ControlLabel>{person.diverIdNumber}</ControlLabel></Row>
                <Row><ControlLabel>{person.email}</ControlLabel></Row>
                <Row><ControlLabel>{person.phone}</ControlLabel></Row>
                <Row><ControlLabel>{person.cognitoUsername}</ControlLabel></Row>
                <Row><ControlLabel>{person.address1}</ControlLabel></Row>
                {
                  person.address2
                  ?
                  <Row><ControlLabel>{person.address2}</ControlLabel></Row>
                  :
                  null
                }
                <Row><ControlLabel>{person.city + ', ' + person.state + ' ' + person.postalCode + ' ' + person.country}</ControlLabel></Row>

              </Col>
              <Col md={6}>
                {
                  picture
                  ?
                  <PersonPhotoViewer personId={person.personId} />
                  :
                  null
                }
              </Col>
            </Row>
          </Grid>
        </Panel.Body>
      </Panel>
    )
  } else {
    return (<Fragment></Fragment>)
  }
}

