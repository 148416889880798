import _ from 'lodash'

export const validator = (field, name, ...validations) => {
  return (state) => {
    for ( let v of validations) {
      let errorMessageFunc = v(state[field], state);
      if (errorMessageFunc) {
        return {[field]: errorMessageFunc(name)};
      }
    }
    return null;
  };
};

export const validatorPerson = (field, name, ...validations) => {
  return (state) => {
    for ( let v of validations) {
      let errorMessageFunc = v(state.person[field], state);
      if (errorMessageFunc) {
        return {[field]: errorMessageFunc(name)};
      }
    }
    return null;
  };
};

export const runRules = (state, validators) => {
  return validators.reduce( (memo, validator) => {
    return Object.assign(memo, validator(state));
  }, {});
};

export const notAllValid = (validationErrors) => {
  //Returns true if all errors are resolved else returns false
  var count = 0
  _.forIn(validationErrors, function(value) {
    if(value !== null){
      count = count + 1
    }
  })

  if(count > 0){
    return true
  } else {
    return false
  }
}