import React, { Component } from "react";
import { connect } from 'react-redux';
import { API, Auth, ConsoleLogger } from 'aws-amplify';
import { Button, Modal, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import "./Test.css";
import { ctl_showVerificationPanel } from '../../redux/control-actions.js';
import VerifyDiverModal from '../VerifyDiverModal/VerifyDiverModal.js';
import EditPerson from '../EditPerson/EditPerson.js';
import EditLeadership from '../EditLeadership/EditLeadership.js'
import person from '../../model/Person.js';
import certification from '../../model/Certification.js'
import certificationOffering from '../../model/CertificationOffering.js'
import certificationBatch from '../../model/CertificationBatch.js'
import leadership from '../../model/Leadership.js'
import { listAddPeople } from '../../model/presentation/certificationViews.js'
import moment from 'moment';
import { globalSetPerson } from '../../redux/global/actions.js';
import { certificationBatchStatus } from '../../model/statusCodes.js'
import {insuranceAgreement, leadershipAgreement, instructorTrainerAgreement, divemasterAgreement, assistantInstructorAgreement, instructorAgreement } from '../EditLeadership/agreements.js'
import uuid from 'uuid'
import _ from 'lodash'
import Avatar from 'react-avatar'

const mapStateToProps = (state) => {
  return {
    authState: state.authState,
    authData: state.authData,
    verification: state.verification,
    person: state.person
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showVerification: (visible) => {dispatch(ctl_showVerificationPanel(visible))},
    diverVerified: (diverVerified, diverCertifications) => {dispatch(diverVerified(diverVerified, diverCertifications))},
    setPerson: (person) => {dispatch(globalSetPerson(person))}
  }
}

class Test extends Component {
  constructor(props){
    super(props);
    let data = {}
    this.state = {
      test: 'testing',
      result: null,
      person: new person(data),
      testBatch : {}
    }
  }

  async componentDidMount(){
    //await this.test()
    // var data = await certificationBatch.findById('3fc66349-b3de-46c1-ac08-508073db707b')
    // this.setState({testBatch: data})
    console.log(Auth.currentAuthenticatedUser());
  }
  async removeProfile(){
    let user = await Auth.currentAuthenticatedUser()
    let result = await Auth.updateUserAttributes(user, {
      'profile': ''
    })
    user = await Auth.currentAuthenticatedUser()
    console.log(user);
  }

  async setPerson(){
    console.log('set person');
    console.log('diver verification');
    console.log(this.props.verification.verifiedDiverIdNumber);
    var newPerson = await person.findByDiverId(this.props.verification.verifiedDiverIdNumber)
    console.log(newPerson);
    this.props.setPerson(newPerson);
    console.log(this.props)
  }

  async test(){
    console.log('testing save api')
    var result = await person.findById('ec1e4306-6877-44ea-b6f3-bd42b0b7e880')
    //var result = await person.verifyDiver('BenJamin','BrOwN', moment('11/18/1982','MM/DD/YYYY').format())
    console.log('got a person')
    console.log(result)
    result.address1 = 'my new address here'
    var saveResult = await result.save()
     console.log('save result')
     console.log(saveResult)
    //this.setState({result: result})
  }

  testHandler = (event) => {
    console.log('in the verifyCallback function');
    console.log(this.props.verification);
  }
  testFind = async () => {
    var newPerson = await person.findByIdentityId()
    console.log(newPerson);
  }
  dunpUserData = async () => {
    console.log('dumping user authdata');
    console.log(this.props.authData);
    console.log('token');
    console.log(this.props.authData.signInUserSession.idToken.payload);
  }
  listGroups = () => {
    console.log('groups')
    console.log(this.props.authData.signInUserSession.idToken.payload['cognito:groups']);
  }
  getCourseOfferings = async () => {
    var res =await  certificationOffering.getListOfOfferings()
    console.log(res)
  }
  testListCertsForInstructor = async () => {
    var res = await certification.listCertsForThisInstructor()
    var view = await listAddPeople(res)
    console.log(view);
    this.setState({arrCert: view})
    //console.log(res);
  }
  testSaveCert = async () => {
    var res = await certification.listCertsForThisInstructor()
    console.log(res);
    for(var i = 0; i<res.length; i++){
      console.log(res[i].certifiedPersonId)
      res[i].status = 'pending'
      await res[i].save()
    }

  }
  testCertBatch = async () => {
    var testDiver = await person.findByDiverId('006768')
    console.log(testDiver);
    console.log(testDiver.certifications);
    var batchData = {
      batchId: uuid(),
      status: certificationBatchStatus.new,
      creatorUsername: this.props.authData.username,
      certifications: []
    }
    for(var i=0; i < testDiver.certifications.length; i++){
      console.log(i);
      batchData.certifications.push(testDiver.certifications[i].certId)
      console.log(batchData);
    }
    console.log(batchData);
    var batch = new certificationBatch(batchData)
    console.log(batch);
    batch.save()
  }
  testFindCertBatch = async() => {
    var res = await certificationBatch.findById('070cdf2e-e2eb-4ab7-9d57-92cdacc48ce3')
    console.log(res);
  }

  testFindCertBatchBystatus = async () => {
    var res = await certificationBatch.listByStatus('new')
    console.log(res);
  }

  testListCertsByStatusAndInstructor = async () => {
    var res = await certification.listCertsByStatusAndInstructor('complete', 'f5b085b5-7fde-4f99-98dd-6986cbffe1e4')
    console.log(res);
  }
  testListPerson = async () => {
    var res = await person.listAllPeople()
  }
  testFindByName = async () => {
    var res = await person.findByName('Benjamin', 'Brown')
    console.log(res);
  }

  testGetLeadership = async () => {
    var res = await leadership.findByPersonId('7efa5ee4-19f1-4a52-8985-929401d24f39')
    console.log(res)
    this.setState({leadershipRecord: res})
  }

  testSaveLeadership = async () => {
    console.log('save leadership - getting recors')
    var res = await leadership.findByPersonId('7efa5ee4-19f1-4a52-8985-929401d24f39')
    res.acceptAgreement = false
    console.log('saving leadership')
    var res2 = await res.save()
    console.log(res2)
  }

  testSavePhoto = async (event) => {
    var reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    console.log('save photo')
    reader.onload = async function(){
      var newPerson = await person.findById('7efa5ee4-19f1-4a52-8985-929401d24f39')
      console.log(newPerson)
      console.log(reader)
      var res = await newPerson.saveImage(reader.result)
      console.log(res)
    } 
  }

  testGetPhoto = async () => {
    var newPerson = await person.findById('7efa5ee4-19f1-4a52-8985-929401d24f39')
    var res = await newPerson.getImage()
    this.setState({diverImg: res})
    console.log(res)
  }

  testGetPerson = async() => {
    var newPerson = await person.findByIdentityId()
    console.log(newPerson)
  }
  render() {
    // let apiName = 'dev-diverportal-api';
    // let path = '/person/diver/100100';
    // API.get(apiName, path).then(response => {
    //     console.log(response)
    // }).catch(error => {
    //     console.log('cathing error')
    //     console.log(error.response)
    // })

    console.log('from home. Window data')
    console.log(window.location)
    console.log(this.state);
    return (
      <div className="Home">
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
        <Button type='button' onClick={() => this.props.showVerification (true)}>Show Verification</Button>
        <Button type='button' onClick={() => this.test()}>Save Test</Button>
        <Button type='button' onClick={() => this.removeProfile()}>Remove Profile</Button>
        <Button type='button' onClick={() => this.setPerson()}>Set Person</Button>
        <Button type='button' onClick={() => this.testFind()}>Test Find</Button>
        <Button type='button' onClick={() => this.dunpUserData()}>Dump User Data</Button>
        <Button type='button' onClick={() => this.listGroups()}>List Groups</Button>
        <Button type='button' onClick={() => this.getCourseOfferings()}>Get Course Offerings</Button>
        {/* <Button type='button' onClick={() => this.testListCertsForInstructor()}>List Certs for this instructor</Button> */}
        <Button type='button' onClick={() => this.testSaveCert()}>Test change and save cert</Button>
        <Button type='button' onClick={() => this.testListPerson()}>Test List Person</Button>
        <Button type='button' onClick={() => this.testListCertsByStatusAndInstructor()}>test cert list</Button>
        <Button type='button' onClick={() => this.testCertBatch()}>Test Cert Batch</Button>
        <Button type='button' onClick={() => this.testFindByName()}>Find By Name</Button>
        <Button type='button' onClick={() => this.testSaveLeadership()}>test Save leadership</Button>
        <Button type='button' onClick={() => this.testGetPhoto()}>test get photo</Button>
        <Button type='button' onClick={() => this.testGetPerson()}>test get person</Button>
        <FormGroup controlId='formControlFile'>
                <ControlLabel>Diver Photo</ControlLabel>
                <FormControl type='file' onChange={this.testSavePhoto}/>
              </FormGroup>
              <Avatar src={this.state.diverImg}  />
        {/* <EditLeadership leadershipRecordId='0d03371e-cf07-47d9-8c9e-88f730fb6c97' /> */}
        {/* <VerifyDiverModal verifyCallback={this.testHandler}/>
        <EditPerson savePerson={this.testHandler} person={this.state.person} /> */}
        {/* <CertificationList arrCert={this.state.arrCert} /> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Test)

;
