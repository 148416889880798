//functions to aid in presentation of certification Objects
//import certification from '../Certification.js'
//import person from '../Person.js'

export const addPeople = async function(cert){
  //returns a new object with the certification, diver and instructor person objects as properties
  var view = {
    certification: cert,
    diver: await cert.getThisDiver(),
    instructor: await cert.getThisInstructor()
  }
  return view
}

export const addDiver = async function(cert){
  //returns a new object with the certification and diver person objects as properties
  var view = {
    certification: cert,
    diver: await cert.getThisDiver()
  }
  return view
}

export const addInstructor = async function(cert){
  //returns a new object with the certification and diver person objects as properties
  var view = {
    certification: cert,
    instructor: await cert.getThisInstructor()
  }
  return view
}

export const listAddPeople = async function(certs = []){
  //given a list of certifications, with all the associated people and record index
  var list = []
  for(var i = 0; i < certs.length; i++){
    var record = await addPeople(certs[i])
    list.push({...record, _id: i})
  }
  return list
}

export const listAddDiver = async function(certs = []){
  //given a list of certifications, with diver associated and record index
  var list = []
  for(var i = 0; i < certs.length; i++){
    var record = await addDiver(certs[i])
    list.push({...record, _id: i})
  }
  return list
}

export const listAddInstructor = async function(certs = []){
  //given a list of certifications, with instructor associated and record index
  var list = []
  for(var i = 0; i < certs.length; i++){
    var record = await addInstructor(certs[i])
    list.push({...record, _id: i})
  }
  return list
}