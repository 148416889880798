//validation rules from FindDiverPanel

import { validator } from '../../libs/validation/validator.js';
import { required, isNumeric } from '../../libs/validation/rules.js';

export const fieldValidations = [
  validator("givenName", "Given Name", required),
  validator("surname", "Surname", required),
//  validator("diverIdNumber", "Diver Id Number", required),
  validator("month", "Month", required, isNumeric),
  validator("day", "Day", required, isNumeric),
  validator("year", "Year", required, isNumeric),
];
