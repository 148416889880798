import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Button, Grid, Row, Col, ControlLabel } from 'react-bootstrap'
import VerifyDiverModal from '../VerifyDiverModal/VerifyDiverModal.js'
import { ctl_showVerificationPanel } from '../../redux/control-actions.js';
import { globalSetPerson, refreshAuthData } from '../../redux/global/actions.js';
import { handleAuthStateChange } from '../withAuthenticator/actions.js'
import person from '../../model/Person.js';
import EditPerson from '../EditPerson/EditPerson.js';
import _ from 'lodash';
import PropTypes from 'prop-types';
//Everything required to find and connect a person entity to a  logi profile

const mapStateToProps = (state) => {
  return {
    authState: state.authState,
    authData: state.authData,
    person: state.person,
    verification: state.verification
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showVerification: (visible) => {dispatch(ctl_showVerificationPanel(visible))},
    setPerson: (person) => {dispatch(globalSetPerson(person))},
    refreshAuthData: () => {dispatch(refreshAuthData())},
    handleAuthStateChange: (authState, data) => {dispatch(handleAuthStateChange(authState, data))}
  }
}



class ConnectPerson extends Component {

  constructor(props){
    super(props)
      this.state = {
        personEditorVisible: true
      }
      this.verifyCallback = this.verifyCallback.bind(this)
      this.connectPerson = this.connectPerson.bind(this)
      this.savePerson = this.savePerson.bind(this)

  }
  async verifyCallback(){
    if(this.props.verification.diverVerified === true){
      //var connectedUser = await Auth.currentAuthenticatedUser()
      var newPerson = await person.findByDiverId(this.props.verification.verifiedDiverIdNumber)
      newPerson.email = this.props.authData.signInUserSession.idToken.payload.email
      newPerson.phone = this.props.authData.signInUserSession.idToken.payload.phone_number
      await newPerson.save()
      this.props.setPerson(newPerson);
      this.setState({personEditorVisible: true})
    }
  }

  async connectPerson(){
    var user = await Auth.currentAuthenticatedUser()
    var result = await Auth.updateUserAttributes(user, {
      'profile': this.props.person.personId
    })
    if(result ===  'SUCCESS'){
      var workingPerson = this.props.person
      workingPerson.cognitoUsername = this.props.authData.username
      await workingPerson.save()
      this.props.setPerson(await workingPerson.addCognitoIdentity())
      var connectedUser = await Auth.currentAuthenticatedUser()
      Auth.signOut().then(() => {this.props.handleAuthStateChange('signedOut', {})})
    }
  }

  async savePerson(editedPerson){
    await editedPerson.save()
    this.props.setPerson(editedPerson)
    this.setState({personEditorVisible: false})
  }
  welcome = () => {
    return (
      <Fragment>
        <h1>Welcome Diver! Let's get started by finding your records.</h1>
        <Button onClick={() => this.props.showVerification(true)}>Find My Records</Button>
      </Fragment>
    )
  }

  connectPanel = () => {
    return (
      <Fragment>
        <Row><h2>All set. Verify Your details and connect</h2></Row>
        <Row><ControlLabel>{this.props.person.givenName + ' ' + this.props.person.surname}</ControlLabel></Row>
        <Row><ControlLabel>{this.props.person.email}</ControlLabel></Row>
        <Row><ControlLabel>{this.props.person.phone}</ControlLabel></Row>
        <Row><ControlLabel>{this.props.person.cognitoUsername}</ControlLabel></Row>
        <Row><ControlLabel>{this.props.person.address1}</ControlLabel></Row>
        {
          this.props.address2
          ?
          <Row><ControlLabel>{this.props.person.address2}</ControlLabel></Row>
          :
          null
        }
        <Row><ControlLabel>{this.props.person.city + ', ' + this.props.person.state + ' ' + this.props.person.postalCode + ' ' + this.props.person.country}</ControlLabel></Row>
        <Row><h3>Click connect to link your profile and log out. Log in again to enjoy the full benefits of this system</h3></Row>
        <Row><Button onClick={() => this.connectPerson()} type='button'>Connect</Button></Row>
      </Fragment>
    )
  }

  render(){
    return(
      <Fragment>
        <VerifyDiverModal verifyCallback={() => this.verifyCallback()}/>
        <Grid fluid={true}>
          <Row>
            <Col md={12}>
              {
                this.props.verification && this.props.verification.diverVerified
                ?
                <Fragment>
                  {
                    this.state.personEditorVisible === true && this.props.person !== null && _.has(this.props.person, 'identityId')
                    ?
                    <Fragment>
                      <h2>Verify your Contact Details</h2>
                      <EditPerson person={this.props.person} savePerson={this.savePerson} allowEmailChange={false} {...this.props} />
                    </Fragment>
                    :
                    null
                  }
                  {
                    this.state.personEditorVisible === false && this.props.person !== null
                    ?
                    this.connectPanel()
                    :
                    null
                  }
                </Fragment>
                :
                this.welcome()
              }
            </Col>
          </Row>
        </Grid>
      </Fragment>
    )
  }
}

ConnectPerson.propTypes = {
  authState: PropTypes.string.isRequired,
  authData: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  verification: PropTypes.object.isRequired,
  showVerification: PropTypes.func.isRequired,
  setPerson: PropTypes.func.isRequired

}
export default connect(mapStateToProps, mapDispatchToProps)(ConnectPerson)
