export const initialState = {
  authState: null,
  authData: null,
  person: null,
  verification: {
    diverVerified: false,
    ctl_showVerificationPanel: false,
    diverCertifications: [],
    verifiedDiverIdNumber: ''
  },
  instructor: {
    allowedCertifications: []
  }
}
