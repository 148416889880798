import React from 'react'
import {version} from '../../config.js'

const About = function(props) {
    return(
        <div>
            <p>{'Portal Version: ' + version}</p>
        </div>
    )
}
export default About