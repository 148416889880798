//global reducers
import update from 'immutability-helper'
import { Auth } from 'aws-amplify'

export const globalSetPerson = (state, action) => {

  let newState = update(state, {
    person: {$set: action.person}
  })
  return newState
}

export const refreshAuthData = async (state, action) => {
  if(state.authState === 'signedIn'){
    var newAuthData = await Auth.currentAuthenticatedUser()
    var newState = update(state, {
      authData: {$set: newAuthData}
    })
    return newState
  }
}

export const setAllowedCertifications = (state, action) => {
  let newInstructor = update(state.instructor, {
    allowedCertifications: {$set: action.allowedCerts}
  })

  let newState = update(state, {
    instructor: {$set: newInstructor}
  })
  return newState
}