import React, { Component } from 'react';
import { Authenticator, Greetings, SignUp } from 'aws-amplify-react';
import { connect } from 'react-redux';
import { handleAuthStateChange } from './actions.js';

export function withAuthenticator(Comp, includeGreetings = false, authenticatorComponents = []) {
  const mapStateToProps = (state) => {
    return {
      authState: state.authState,
      authData: state.authData,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      //authenticate: (authState) => {dispatch(authenticate(authState))}
      handleAuthStateChange: (authState, data) => {
        dispatch(handleAuthStateChange(authState, data));
      },
    };
  };
  class AuthWrapper extends Component {
    // authTester = async (authState, data) => {
    //   console.log('auth wrapper testing');
    //   console.log('wrapper state');
    //   console.log(authState);
    //   console.log('wrapper data');
    //   console.log(data);
    //   console.log('getting logged in user data');
    //   var authData = await Auth.currentAuthenticatedUser();
    //   this.props.handleAuthStateChange(authState, authData)
    // }
    render() {
      // const { authState, authData } = this.state;
      const authState = this.props.authState;
      const authData = this.props.authData;
      const signedIn = authState === 'signedIn';
      if (signedIn) {
        return (
          <div>
            {includeGreetings ? (
              <Greetings authState={authState} authData={authData} onStateChange={this.props.handleAuthStateChange} />
            ) : null}
            <Comp
              {...this.props}
              authState={authState}
              authData={authData}
              onStateChange={this.props.handleAuthStateChange}
            />
          </div>
        );
      }

      return (
        <Authenticator
          {...this.props}
          hideDefault={authenticatorComponents.length > 0}
          onStateChange={this.props.handleAuthStateChange}
          hide={[SignUp]}
          // onStateChange={this.authTester}
          children={authenticatorComponents}
        />
      );
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(AuthWrapper);
}
