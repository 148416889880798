import React, { Component } from 'react'
import {Panel, Grid, Row, Col, ControlLabel} from 'react-bootstrap'
import _ from 'lodash'

class PersonCertificationViewer extends Component {

    mapCertsToList = (certs) => {
        var sorted = _.orderBy(certs, ['certLevel'],['desc'])
        return sorted.map((c, idx) => {
          return (<Row key={idx}><ControlLabel>{c.certName}</ControlLabel></Row>)
        })
    }

      render(){
        return(
            <Panel>
                <Grid>
                    <Row><h2>My Certifications</h2></Row>
                    <Row><ControlLabel>{'Name: ' + this.props.givenName + ' ' + this.props.surname}</ControlLabel></Row>
                    <Row><ControlLabel>Certifications:</ControlLabel></Row>
                    {this.mapCertsToList(this.props.certifications)}
                </Grid> 
            </Panel>
        )
    }
}
export default PersonCertificationViewer