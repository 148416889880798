//build and return options for various Combo Boxes used throughout the UI
import {certificationStatus, certificationBatchStatus } from '../model/statusCodes.js'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import React from 'react'
import _ from 'lodash'

export const CbCertificationStatusOptions = (props) => {
  var options = []
  var i = 0
  _.forOwn(certificationStatus, (val, key) => {
    options.push(
      <option key={i} value={val}>{key}</option>
    )
    i = i + 1
  })
  return(
    <FormGroup controlId='formControlsSelect'>
      <ControlLabel>Select Status</ControlLabel>
      <FormControl componentClass='select' placeholder='select status...' value={props.value} onChange={props.onChange}>
        {options}
      </FormControl>
    </FormGroup>
  )
}

export const CbCertificationBatchStatusOptions = (props) => {
  var options = []
  var i = 0
  _.forOwn(certificationBatchStatus, (val, key) => {
    options.push(
      <option key={i} value={val}>{key}</option>
    )
    i = i + 1
  })
  return(
    <FormGroup controlId='formControlsSelect'>
      <ControlLabel>Select Status</ControlLabel>
      <FormControl componentClass='select' placeholder='select status...' value={props.value} onChange={props.onChange}>
        {options}
      </FormControl>
    </FormGroup>
  )
}