//data model for person objects
import { apiName } from '../config.js'
import { API } from 'aws-amplify'
const _ = require('lodash');

var CertificationBatch = function(input){

  //load input data into internal data object
  this.data = input

  //initialize the certifications array
  if(!_.has(this.data, 'certifications')){
    this.data.certifications = []
  }

  //define properties
  Object.defineProperty(this, 'batchId', {
    get: function(){
      return this.data.batchId
    },
    set: function(value) {
      this.data.batchId = value
    },
    enumerable: true
  })

  Object.defineProperty(this, 'status', {
    get: function(){
      return this.data.status
    },
    set: function(value) {
      this.data.status = value
    },
    enumerable: true
  })

  Object.defineProperty(this, 'createdOn', {
    get: function(){
      return this.data.createdOn
    },
    set: function(value) {
      this.data.createdOn = value
    },
    enumerable: true
  })

  Object.defineProperty(this, 'lastModified', {
    get: function(){
      return this.data.lastModified
    },
    set: function(value) {
      this.data.lastModified = value
    },
    enumerable: true
  })

  Object.defineProperty(this, 'creatorUsername', {
    get: function(){
      return this.data.creatorUsername
    },
    set: function(value) {
      this.data.creatorUsername = value
    },
    enumerable: true
  })

  Object.defineProperty(this, 'creatorIdentityId', {
    get: function(){
      return this.data.creatorIdentityId
    },
    set: function(value) {
      this.data.creatorIdentityId = value
    },
    enumerable: true
  })
  Object.defineProperty(this, 'batchName', {
    get: function(){
      return this.data.batchName
    },
    set: function(value) {
      this.data.batchName = value
    },
    enumerable: true
  })

  Object.defineProperty(this, 'certifications', {
    get: function(){
      return this.data.certifications
    },
    set: function(value) {
      this.data.certifications = value
    },
    enumerable: true
  })

  //Object Functions
  CertificationBatch.prototype.save = async function(){
    var data = this.data
    var path = '/admin/certifications/batch'
    var myInit = {
      body: {data}
    }
    try {
      var response = await API.post(apiName, path, myInit)
      return new CertificationBatch(response)
    } catch (e) {
      console.log(e)
      alert(e)
    }
  }
}

CertificationBatch.findById = async function(batchId){

  var path = '/admin/certifications/batch/' + batchId

  try {
    var response = await API.get(apiName, path)
    if (response.err){
      throw(response.err)
    }
    var newCertBatch = new CertificationBatch(response)
    return newCertBatch

  } catch (e) {
    alert(e)
  }
}

CertificationBatch.listByStatus = async function(status){
  var path = '/admin/certifications/batch/list/' + status
  try {
    var response = await API.get(apiName, path)
    if (response.err){
      throw(response.err)
    }
    var arrCertBatches = response.map((item) => {return new CertificationBatch(item)})
    return arrCertBatches
  } catch (e) {

  } finally {

  }
}

export default CertificationBatch