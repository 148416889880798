import React from 'react'
import { Grid, Row, Col, ControlLabel } from 'react-bootstrap'
import ValidatedFormGroup from '../ValidatedFormGroup.js'
import ValidatedDateFields from '../Generic/ValidatedDateFields/ValidatedDateFields.js'
import { insuranceAgreement } from './agreements.js'
import PropTypes from 'prop-types'

const InsuranceEditor = (props) => {
    const {insuranceAgency, insurancePolicyNumber, month, day, year, validationErrors, getErrorFor, handleChange } = props

    return(
        <Grid fluid={true}>
        <Row>
          <Col md={12}>
            {insuranceAgreement}
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <ValidatedFormGroup 
              id='insuranceAgency'
              label='Insurance Agency'
              showError={true}
              errorText={getErrorFor('insuranceAgency')}
              type='text'
              value={insuranceAgency || ''}
              onChange={handleChange}
            />
          </Col>
          <Col md={6}>
          <ValidatedFormGroup 
              id='insurancePolicyNumber'
              label='Insurance Policy Number'
              showError={true}
              errorText={getErrorFor('insurancePolicyNumber')}
              type='text'
              value={insurancePolicyNumber || ''}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ControlLabel>Insurance Expiration Date</ControlLabel>
          </Col>
          <Col md={12}>
            <ValidatedDateFields 
              month={month}
              day={day}
              year={year}
              errors={validationErrors}
              handleChange={handleChange}
            />>
          </Col>
        </Row>
      </Grid>
    )
}

InsuranceEditor.propTypes = {
    handleChange: PropTypes.func.isRequired,
    getErrorFor: PropTypes.func.isRequired,
    month: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    insurancePolicyNumber: PropTypes.string.isRequired,
    insuranceAgency: PropTypes.string.isRequired,
    validationErrors: PropTypes.object.isRequired

}

export default InsuranceEditor