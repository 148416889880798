import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => {
  return {
    instructor: state.person
  };
};


class CertufyLeader extends Component {

  render() {

    return (
      <div>
        {
          this.props.instructor && this.props.instructor !== null ?
            <Fragment>
              <Grid>
                <Row>
                  <Col md={12}>
                    <div dangerouslySetInnerHTML={{ __html: `<iframe frameborder="0" style="height:500px;width:99%;border:none;" src='https://forms.scubaeducators.org/scubaeducators/form/LeadershipCertification/formperma/uX6lUmbLev0Po-u7vHpQ1CrgCaVvFRgNLko8TEwPcqs?instructorFirstName=${this.props.instructor.data.givenName}&instructorLastName=${this.props.instructor.data.surname}&instructorIdNumber=${this.props.instructor.data.diverIdNumber}'></iframe>` }}></div>
                  </Col>
                </Row>
              </Grid>
            </Fragment>
            :
            null
        }
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(CertufyLeader));
