//helper componentClaimport React
import React, { Component } from 'react'
import { Grid, Row, Col, Button, } from 'react-bootstrap'
import ValidatedFormGroup from '../ValidatedFormGroup.js'
import person from '../../model/Person.js'
import { runRules, notAllValid } from '../../libs/validation/validator.js'
import { fieldValidations } from './validations.js'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

class FindByDiverId extends Component {
  constructor(props){
    super(props)
    this.state = {
      validationErrors: {}
    }
    this.state.validationErrors = runRules(this.state, fieldValidations)
  }

  getErrorFor = (field) => {
    return this.state.validationErrors[field] || "";
  }

  handleComplete = async () => {
    var newPerson = await person.findByDiverId(this.state.diverIdNumber)
    console.log(newPerson)
    this.props.callback(false, newPerson)
  }

  handleChange = (event) => {
    let newState = update(this.state, {
      [event.target.id]: {$set: event.target.value}
    });
    newState.validationErrors = runRules(newState, fieldValidations);

    this.setState(newState);
  }

  render(){
    return (
      <Grid fluid={true}>
        <Row>
          <Col md={12}>
           <ValidatedFormGroup
              id='diverIdNumber'
              label='Diver ID Number'
              showError={true}
              errorText={this.getErrorFor("diverIdNumber")}
              type='text'
              autoFocus
              value={this.state.diverIdNumber || ''}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button type='button' disabled={notAllValid(this.state.validationErrors)} onClick={() => this.handleComplete()}>Search</Button>
            <Button type='button' onClick={() => this.props.callback(false)}>Close</Button>
          </Col>
        </Row>
      </Grid>
    );
  }
}

FindByDiverId.propTypes = {
  callback: PropTypes.func.isRequired
}

export default FindByDiverId

