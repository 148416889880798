import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Button, Panel } from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import certification from '../../model/Certification.js';
import CreatePerson from '../EditPerson/CreatePerson.js';
import EditPerson from '../EditPerson/EditPerson.js';
import CertifyUi from './CertifyUi.js';
import FindDiverUiModal from '../FindDiverUiModal/FindDiverUiModal.js';
import PersonInfoPanel from '../PersonInfoPanel/PersonInfoPanel.js';
import _ from 'lodash';
import uuid from 'uuid';

const mapStateToProps = (state) => {
  return {
    instructor: state.person,
    allowedCertifications: state.instructor.allowedCertifications,
  };
};

class Certify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createPersonVisible: false,
      findDiverByIdNumberVisible: false,
      editPersonVisible: false,
      certificationDetailsVisible: true,
      diver: null,
    };

    this.handleCreatedPerson = this.handleCreatedPerson.bind(this);
    this.handleFindDiverByIdModal = this.handleFindDiverByIdModal.bind(this);
    this.handleCertify = this.handleCertify.bind(this);
  }

  handleCreatedPerson = (person) => {
    this.setState({
      diver: person,
      createPersonVisible: false,
      ctl_flowCompleteRedirect: false,
    });
  };

  handleEditedPerson = async (person) => {
    try {
      await person.save();
      this.setState({
        diver: person,
        editPersonVisible: false,
        certificationDetailsVisible: true,
        ctl_flowCompleteRedirect: false,
      });
    } catch (error) {
      alert('Save failed \n' + error);
    }
  };

  handleCertify = async (certDate, certName, cardReturn) => {
    var certInfo = _.find(this.props.allowedCertifications, { name: certName });
    var certificationData = {
      certId: uuid(),
      certName: certName,
      certLevel: certInfo.level,
      certDate: certDate.format(),
      instructorId: this.props.instructor.personId,
      instructorFirstName: this.props.instructor.givenName,
      instructorLastName: this.props.instructor.surname,
    };
    var cert = new certification(certificationData);
    await this.state.diver.certify(cert, cardReturn);
    this.setState({ ctl_flowCompleteRedirect: true });
  };

  handleFindDiverByIdModal = (visible, person = null) => {
    if (person === null) {
      this.setState({ findDiverByIdNumberVisible: visible });
    } else {
      console.log('find callback');
      console.log(person);
      this.setState({ diver: person, findDiverByIdNumberVisible: visible });
    }
  };

  renderPersonSelectButtons = () => {
    return (
      <Fragment>
        <Button
          type="button"
          onClick={() => this.setState({ createPersonVisible: false, findDiverByIdNumberVisible: true })}
        >
          Find a current diver
        </Button>
        <Button
          type="button"
          onClick={() => this.setState({ createPersonVisible: true, findDiverByIdNumberVisible: false, diver: null })}
        >
          Add a new diver
        </Button>
      </Fragment>
    );
  };
  render() {
    if (this.state.ctl_flowCompleteRedirect === true) {
      return <Redirect to="/instructor/certifications/status" push={true} />;
    }
    return (
      <Fragment>
        <FindDiverUiModal callback={this.handleFindDiverByIdModal} visible={this.state.findDiverByIdNumberVisible} />
        <Panel>
          <Panel.Heading>
            <Panel.Title>Person Being Certified</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Grid fluid={true}>
              <Row>
                {this.state.diver === null && this.state.createPersonVisible === false ? (
                  <Col md={12}>{this.renderPersonSelectButtons()}</Col>
                ) : (
                  <Fragment>
                    {this.state.editPersonVisible === false && this.state.createPersonVisible === false ? (
                      <Col md={6}>
                        <PersonInfoPanel person={this.state.diver} msg="" showPicture={true} />
                        <Button
                          type="button"
                          onClick={() => this.setState({ editPersonVisible: true, certificationDetailsVisible: false })}
                        >
                          Change Diver Details and Picture
                        </Button>
                        {/* {PersonInfoPanel(this.state.diver)} */}
                      </Col>
                    ) : null}
                    <Col md={6}>{this.renderPersonSelectButtons()}</Col>
                  </Fragment>
                )}
              </Row>
            </Grid>
          </Panel.Body>
        </Panel>
        {this.state.editPersonVisible === true ? (
          <EditPerson person={this.state.diver} savePerson={this.handleEditedPerson} allowEmailChange={false} />
        ) : null}
        {this.state.createPersonVisible === true ? (
          <CreatePerson savePersonCallback={this.handleCreatedPerson} />
        ) : null}
        {this.state.diver !== null && this.state.certificationDetailsVisible === true ? (
          <CertifyUi
            callback={this.handleCertify}
            allowedCertifications={this.props.allowedCertifications}
            person={this.state.diver}
          />
        ) : null}
      </Fragment>
    );
  }
}

// class CertifyUi extends Component {
//   constructor(props){
//     super(props)
//     this.state = {
//       month: '',
//       day: '',
//       year: '',
//       cardReturn: 'student',
//       cert_name: 'OPEN WATER DIVER',
//       validationErrors: {}
//     }
//
//     this.state.validationErrors = runRules(this.state, fieldValidations)
//
//     this.handleChange = this.handleChange.bind(this)
//     this.handleComplete = this.handleComplete.bind(this)
//   }
//
//   getErrorFor(field){
//     return this.state.validationErrors[field] || "";
//   }
//
//   handleComplete = async () => {
//     var certDate = moment(this.state.month + '/' + this.state.day + '/' + this.state.year, 'MM/DD/YYYY')
//     this.props.callback(certDate, this.state.cert_name, this.state.cardReturn)
//   }
//
//   handleChange = (event) => {
//     let newState = update(this.state, {
//       [event.target.id]: {$set: event.target.value}
//     });
//     newState.validationErrors = runRules(newState, fieldValidations);
//     this.setState(newState);
//   }
//
//   handleCbChange(attr, event){
//     this.setState({
//       [attr]: event.target.value
//     })
//   }
//
//   render(){
//     console.log();
//     var cbOptions = this.props.allowedCertifications.map((item, idx) => {
//       return (
//         <option key={idx} value={item.name}>{item.name}</option>
//       )
//     })
//     return (
//       <Panel>
//         <Panel.Heading>
//           <Panel.Title><h3>Certification Information</h3></Panel.Title>
//         </Panel.Heading>
//         <Panel.Body>
//           <Grid>
//             <Row>
//               <Col md={6}>
//                 <FormGroup controlId='formControlsSelect'>
//                   <ControlLabel>Certification</ControlLabel>
//                   <FormControl componentClass="select" placeholder='select cert...' value={this.state.cert_name || {}} onChange={this.handleCbChange.bind(this, "cert_name")}>
//                     {cbOptions}
//                   </FormControl>
//                 </FormGroup>
//               </Col>
//               <Col md={6}>
//                 <FormGroup controlId='deliverySelect'>
//                   <ControlLabel>Return Card To:</ControlLabel>
//                   <FormControl componentClass='select' value={this.state.cardReturn || {}} onChange={this.handleCbChange.bind(this, "cardReturn")}>
//                     <option value='student'>Return to Student</option>
//                     <option value='instructor'>Return to instructor</option>
//                     <option value='training_facility'>Return to Training Facility</option>
//                   </FormControl>
//                 </FormGroup>
//               </Col>
//             </Row>
//             <Row>
//               <Col md={12}>
//                 <ControlLabel>Ceertification Date (MM/DD/YYYY)</ControlLabel>
//               </Col>
//             </Row>
//             <ValidatedDateFields
//               month={this.state.month}
//               day={this.state.day}
//               year={this.state.year}
//               errors={this.state.validationErrors}
//               handleChange={this.handleChange}
//             />
//             {/* <Row>
//               <Col xs={4} sm={4} md={4}>
//                 <ValidatedFormGroup
//                   id='month'
//                   label='Month'
//                   showError={true}
//                   errorText={this.getErrorFor("month")}
//                   type='text'
//                   autoFocus
//                   value={this.state.month}
//                   onChange={this.handleChange}
//                 />
//               </Col>
//               <Col xs={4} sm={4} md={4}>
//                 <ValidatedFormGroup
//                   id='day'
//                   label='Day'
//                   showError={true}
//                   errorText={this.getErrorFor("day")}
//                   type='text'
//                   value={this.state.day}
//                   onChange={this.handleChange}
//                 />
//               </Col>
//               <Col xs={4} sm={4} md={4}>
//                 <ValidatedFormGroup
//                   id='year'
//                   label='Year'
//                   showError={true}
//                   errorText={this.getErrorFor("year")}
//                   type='text'
//                   value={this.state.year}
//                   onChange={this.handleChange}
//                 />
//               </Col>
//             </Row> */}
//             <Row>
//               <Col md={4}>
//                 <Button type='button' onClick={() => this.handleComplete()} disabled={notAllValid(this.state.validationErrors)}>Submit</Button>
//               </Col>
//             </Row>
//           </Grid>
//         </Panel.Body>
//       </Panel>
//     )
//   }
// }
//
// CertifyUi.propTypes = {
//   callback: PropTypes.func.isRequired,
//   person: PropTypes.object.isRequired,
//   allowedCertifications: PropTypes.array.isRequired
// }

export default withRouter(connect(mapStateToProps)(Certify));
