import React, { Fragment } from 'react'


export const divemasterAgreement = 

    <Fragment>
        <ol>
            <li>Every SEI Divemaster must complete the annual renewal form and send with the annual renewal fee to the SEI Office by December 31 of each year. A renewal received after that date is subject to late fees.</li>
            <li>Utilize the skill of Divemaster in a teaching environment such as helping an instructor or working as a Divemaster at a dive site.</li>
        </ol>
    </Fragment>


export const assistantInstructorAgreement = 

    <Fragment>
        <ol>
            <li>Every SEI Assistant Instructor must complete the annual renewal form and send with the annual renewal fee to the SEI Office by December 31 of each year. A renewal received after that date will be subject to late fees. Individuals who did not renew their SEI certification in the previous year will be subject to an additional reactivation fee.</li>
            <li>
                <p>To be eligible for renewal, Assistant Instructors must complete one of the following:</p>
                <ol type='A'>
                    <li>Assist in at least one complete SEI course annually, including open water dives.</li>
                    <li>Attend or assist at an SEI Leadership training course (Divemaster or Assistant Instructor). </li>
                    <li>Attend an approved SEI seminar or workshop, or develop approved SEI educational resources approved by the SEI CEO.</li>
                    <li>Develop approved SEI educational resource materials.</li>
                </ol>
            </li>
        </ol>
    </Fragment>

export const instructorAgreement = 

    <Fragment>
        <ol>
            <li>
                <p>Every SEI Instructor must complete an annual renewal form and send it with the annual renewal fee and proof of insurance--to the SEI Office by December 31 of each year. A renewal received after that date is subject to late fees. Individuals who did not renew their SEI certification in the previous year will be subject to an additional reactivation fee. In addition to completing the form, the Instructor must accomplish one of the following annually:</p>
                <p>
                    <ol type="A">
                        <li>Teach and certify at least six students since the last renewal date. Certifications may be from entry level, advanced, or specialty courses. Note: Team teaching is permitted for renewal purposes and should be listed on student Certification Forms.</li>
                        <li>Attend or assist in an SEI Leadership Institute, or attend an approved SEI seminar or workshop, or develop approved SEI educational resources approved by the SEI CEO.</li>
                    </ol>
                </p>
            </li>
            <li>
                <p>Each Instructor must accumulate Continuing Education Credit (CEC) during the previous three years. A list of available credits is located under POLICIES and PROCEDURES in the SEI Standards and Procedures Manual. Please submit a completed CEC request form with the completed leadership renewal form.</p>
            </li>
            <li>
                <p>Instructors who do not meet all Active Instructor requirements may obtain Inactive Instructor status by sending in a renewal form and appropriate renewal fee. The Inactive Instructor will receive a certification card, but will not be allowed to teach or certify SEI courses or students. Insurance coverage is optional for non-teaching instructors. However, all Instructors should keep in mind what claims-made insurance means. Only claims that are reported to the insurance company during the policy term or within 90 days after expiration date are valid. It is recommended that Professional Liability Insurance be maintained until the statute of limitations for your state has expired since your last class completion date.</p>
            </li>
            <li>
                <p>After the first year, the non-teaching Instructor may return to Active status by completing all requirements for an Active Instructor plus attending an SEI Instructor Update seminar as described under Renewal '1b' above. After 3 years the following will apply:</p>
                <p>
                    <ol type="A">
                        <li>
                            <p>Instructors that have maintained a continuous teaching status with another scuba training agency must complete the following to re-certify.</p>
                            <p>
                                <ol>
                                    <li>Participate in a lecture on SEI teaching philosophy and administrative requirements.</li>
                                    <li>Attend an SEI Instructor Update.</li>
                                    <li>Complete the standards exam or update.</li>
                                    <li>Complete continuing education requirements per #2 above.</li>
                                </ol>
                            </p>
                        </li>
                        <li>
                            <p>Instructors that have not maintained teaching status in another agency for up to 6 years.</p>
                            <p>
                                <ol>
                                    <li>Attend an entire Crossover Institute.</li>
                                    <li>Have all prerequisites for Instructor status.</li>
                                </ol>
                            </p>
                        </li>
                        <li>After 6 years of non-teaching status, attend an SEI Instructor Institute.</li>    
                    </ol>
                </p>
                <p>The above also applies to Instructors returning to Active status from Emeritus status.</p>
            </li>
            <li>U.S. Instructors living in foreign countries or serving in the military often do not have access to continuing education. These Instructors will be considered active as long as they teach at least one class of at least 6 persons and renew annually. Upon return to the U.S., if one has not been attended during the overseas or military service, the Instructor must attend an update within 2 years.</li>
            <li>Instructors who are certified in an Institute within the 3-month period (Oct., Nov., Dec.) preceding the December 31 renewal date need not submit a renewal fee for that year. All other Instructors must complete a renewal form and submit the appropriate fees with proof of insurance.</li>
        </ol>
    </Fragment>

export const instructorTrainerAgreement = 

    <Fragment>
        <ol>
            <li>
                <p>Every SEI Instructor Trainer must complete an annual renewal form and send it with the annual renewal fee and proof of insurance--to the SEI Office by December 31 of each year. A renewal received after that date is subject to late fees. Individuals who did not renew their SEI certification in the previous year will be subject to an additional reactivation fee. In addition to completing the form, and meeting the requirements as listed below for Instructor; an Instructor Trainer must complete the following:</p>
                <p>
                    <ol type="A">
                        <li>Each Instructor Trainer must conduct or staff at least one Institute every two years or they will automatically revert back to the Instructor level.</li>
                        <li>Additional training may be required from time to time as standards, procedures and policies change.</li>
                    </ol>
                </p>
            </li>
        </ol>
    </Fragment>

export const leadershipAgreement = 

    <Fragment>
        <ol>
            <li>Uphold and maintain the standards of all SEI programs as set forth by the most current SEI Standards and Procedures Manual and SEI Instructor Guides.</li>
            <li>Obtain and maintain liability insurance (where applicable) and to comply with the warranties, conditions and limitations of that policy.</li>
            <li>Act at all times in a manner consistent with the safety of students and divers under my supervision.</li>
            <li>Conduct myself in a manner consistent with the best practices and standards of the scuba industry and of the SEI program.</li>
            <li>Fulfill all obligations, written or implied, in any SEI program in which I participate.</li>
            <li>Complete all SEI Instructional Programs (including open water training) in a timely manner.</li>
            <li>Submit all paperwork and fees, upon completion of a student's course of instruction in which all requirements necessary for certification have been performed, within four (4) weeks from the conclusion of the course. Maintain course records for a minimum of five (5) years.</li>
            <li>Teach and certify using SEI standards and materials.</li>
            <li>Refrain from making derogatory, defamatory, slanderous or libelous comments whether in oral or in written form about any scuba agency, organization or group.</li>
            <li>Recognize and teach the importance of protecting our environment and strive to protect it.</li>
            <li>Bear sole responsibility for determining that each student has met all criteria for receiving a successful course completion certification card and certificate in accordance with current SEI standards.</li>
            <li>Report all accidents involving personal injury (including death) that have occurred during SEI training activities immediately and in the event no later than seventy-two (72) hours and in writing to SEI's Legal Counsel upon request. Additionally, on becoming aware of any event, act, error or omission that might reasonably be expected to be the basis of a claim or suit against me, or any SEI leadership member, I will notify SEI headquarters within forty-eight (48) hours.</li>
            <li>Waive, release and forever discharge SEI, its Directors, Officers, shareholders, employees, agents, members, insurers, successors and assigns ("Released Parties"), of and from any and all claims and demands of every nature and description, including but not limited to claims for personal injury (including death) and property damage, and arising directly or indirectly from the teaching of SEI courses, and/or my acts or omissions and the acts or omissions of my employees, agents or other persons under my supervision or control. I further agree to indemnify and save Released Parties harmless from any and all loss, cost damage and expense (including attorney fees) arising from the claim of any third party relating directly or indirectly to the teaching of SEI courses, and/or my acts or omissions and the acts or omissions of my employees, agents or other persons under my supervision or control.</li>
            <li>Maintain proper health and fitness to enable me to safely and competently perform all of my duties and services and, if I should ever become unable to do so, I will immediately notify SEI and stop teaching and supervising diving until I provide a physician's statement satisfactory to SEI that I can safely and competently resume my duties and services. By submitting this application, I represent and warrant that I am physically and mentally fit to safely and competently perform all of my duties and services as an SEI Leadership member, and I acknowledge that SEI reserves the right to request a physician's statement or other proof of fitness at any time.</li>
            <li>Notify SEI headquarters if I have previously been convicted of a felony or crime of moral turpitude (i.e. honesty, integrity, morals). Conviction of such a crime may make me ineligible for continued membership, application for membership or renewal of membership or from teaching or acting in a leadership role.</li>
            <li>Utilize the SEI logo, trademarks and other intellectual property of SEI in line with our policy and in accordance of the most current version of the SEI Standards and Procedures Manual.</li>
            <li>Maintain a current mailing and e-mail address with SEI headquarters.</li>
            <li>Authorize any other scuba diving association or organization with which I am affiliated to release to SEI any and all records and other information pertaining to any investigation, action, or proceeding involving me.</li>
        </ol>

        <h2>I understand that:</h2>
        <ul>
            <li>I am not an agent, employee, or legal representative of SEI.</li>
            <li>My SEI membership is not to be construed as a partnership, joint venture or joint enterprise nor does it establish an agency relationship between me and SEI.</li>
            <li>I may be subject to quality assurance monitoring process conducted by SEI.</li>
            <li>My membership renewal is not solely based upon submission of the renewal application and fee (if applicable). SEI Membership renewal is subject to annual review and acceptance by SEI.</li>
            <li>SEI Leadership cards are issued by SEI and are the property of SEI and must be surrendered upon request by the SEI CEO.</li>
            <li>If I do not renew my membership annually, I lose SEI membership resulting in a suspension of teaching status, and I may have to meet special renewal requirements before renewal will be considered.</li>

        </ul>

        <p>Submitting this application to SEI, confirms that you:</p>
        <ul>
            <li>have read, understand and agree to each of the Leadership Agreement statements above</li>
            <li>understand that failure to adhere to these standards constitutes a breach of the agreement and could result in the loss of your leadership certification status</li>
            <li>have read page 1 and meet the Leadership Renewal Requirements as listed for your leadership level; and that this is binding upon acceptance by SEI.</li>
        </ul>
    </Fragment>

export const insuranceAgreement = 

    <Fragment>
        <p>Liability insurance is required for all:</p>
        <ul>
            <li>Leadership teaching, certifying and/or supervising students and/or divers within the United States and Canada are required to carry a liability insurance policy.</li>
            <li>Leadership teaching, certifying and/ or supervising students and/ or divers that are U.S. Citizens, regardless of location around the world are required to carry a liability insurance policy.***</li>
            <li>Leadership teaching, certifying and/ or supervising students and/ or divers anywhere outside of the United States and Canada must adhere to the liability insurance requirements of the country in which they reside and/ or teach, certify and/ or supervise students and/or divers.</li>
        </ul>
        <p>*Leadership includes individuals who are trained and certified as Divemasters**, Assistant Instructors**, Instructors, Instructor Trainers, Course Directors etc., and require an annual renewal or appointment from SEI headquarters. **Instructors may list Divemasters and Assistant Instructors as additional insured on their policy when those parties are working under the supervision of the Instructor; however this may not provide protection for the DM/AI against legal action as a result of their own negligence. Consult your own legal professional for further information ***So long as such insurance is commercially available</p>
        <p>Each Liability Insurance policy:</p>
        <ul>
            <li>Must provide a minimum of $1,000,000.00 per claim/$2,000,000 annual aggregate.</li>
            <li>Must have added SEI as an additional insured </li>
        </ul>
        <p>I agree that:</p>
        <ul>
            <li>SEI headquarters currently has a copy of my insurance on file (Include a copy of proof of insurance if it is not already on file with SEI)</li>
            <li>I have liability insurance. I acknowledge that any lapse, termination, or failure to have liability insurance will result in an immediate suspension of teaching, certifying and/or supervising privileges.</li>
        </ul>
        <p>To obtain insurance, call Assured Partners (866.577.3483, <a>www.scubains.com</a>) or Divers Alert Network (800.446.2671, <a>www.diversalertnetwork.org</a>)</p>
    </Fragment>