//Actions used globally throughout the application
import { GLOBAL_SET_PERSON, GLOBAL_REFRESH_AUTHDATA, SET_ALLOWED_CERTIFICATIONS } from '../action-types.js'

export const globalSetPerson = (person) => ({
  type: GLOBAL_SET_PERSON,
  person: person
})

export const refreshAuthData = () => ({
  type: GLOBAL_REFRESH_AUTHDATA
})

export const setAllowedCertifications = (allowedCerts) => ({
  type: SET_ALLOWED_CERTIFICATIONS,
  allowedCerts: allowedCerts
})