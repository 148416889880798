//validations for editPerson

import { validator } from '../../libs/validation/validator.js'
import { required, isNumeric } from '../../libs/validation/rules.js'

export const fieldValidations = [
  // validator("phone", "Phone Number", required),
  validator("month", "Month", required, isNumeric),
  validator("day", "Day", required, isNumeric),
  validator("year", "Year", required, isNumeric),
  validator("insursnceAgency", "Insurance Agency", required),
  validator("insurancePolicyNumber", "Insurance Policy Number", required)
]