import React, { Component, Fragment } from 'react'
import { Button, Grid, Row, Col, Panel } from 'react-bootstrap'
import certification from '../../../model/Certification.js'
import GridListChecked from '../../lists/GridListChecked.js'
import { columns } from './gridColumns.js'
import { listAddDiver } from '../../../model/presentation/certificationViews.js'
import FindDiverUiModal from '../../FindDiverUiModal/FindDiverUiModal.js'
import PersonInfoPanel from '../../PersonInfoPanel/PersonInfoPanel.js'
// import { certificationStatus } from '../../../model/statusCodes.js'
import { CbCertificationStatusOptions } from '../../../libs/uiListBuilders.js'
// import _ from 'lodash'

class CertificationInstructor extends Component {

  constructor(props){
    super(props)

    this.state = {
      // sel_instructorId: '11602d54-81d0-473a-b671-cbf307e433d9',
      sel_status: 'complete',
      ctl_selInstructorVisible: false,
      ctl_active_reload: true,
      instructor: {},
      data: [],
      selection: [],
      processingMessage: 'Awaiting Data'
    }
  }

  loadData = async (status, instructorId) => {
    this.setState({processingMessage: 'loading...', data: []})
    var data = []
    var certs = []
    data = await certification.listCertsByStatusAndInstructor(status, instructorId)
    if(data === 'No Records Found'){
      this.setState({processingMessage: 'No Data'})
      return
    } else {
      var view = await listAddDiver(data)
    }
    certs = await listAddDiver(data)
    if(certs && certs.length && certs.length > 0){
      this.setState({
        data: certs,
        processingMessage: 'Awaiting Data'
      })
    } else {
      this.setState({ processingMessage: 'No Data'})
    }
  }

  reloadData = () => {
    if(this.state.instructor && this.state.instructor.personId){
      this.setState({data: []})
      this.loadData(this.state.sel_status, this.state.instructor.personId)
    }
  }

  updateSelection = (selection) => {
    this.setState({selection: selection})
  }

  findByDiverIdUiModalCallback = (visible, person) => {
    if(person && person.personId){
      this.setState({
        ctl_selInstructorVisible: visible,
        instructor: person
      })
      this.loadData(this.state.sel_status, person.personId)
    } else {
      this.setState({ctl_selInstructorVisible: false})
    }
  }

  handleCbChange = (attr, event) => {
    if(this.state.ctl_active_reload !== event.target.value){
      console.log('reload');
      this.setState({ctl_active_reload: false})
    }
    this.setState({
      [attr]: event.target.value
    })
  }

  render(){
    return(
      <Fragment>
        <FindDiverUiModal visible={this.state.ctl_selInstructorVisible} callback={this.findByDiverIdUiModalCallback} title='Find Instructor' />
        <Grid>
          <Row>
            <Col md={3}>
              <PersonInfoPanel person={this.state.instructor} msg='Instructor' />
              {/* {PersonInfoPanel(this.state.instructor, "Instructor")} */}
              <Panel>
                <Panel.Body>
                  <Button type='button' onClick={() => this.setState({ctl_selInstructorVisible: true})}>Select Instructor</Button>
                  <CbCertificationStatusOptions value={this.state.sel_status} onChange={this.handleCbChange.bind(this, 'sel_status')} />
                  <Button type='button' disabled={this.state.ctl_active_reload} onClick={() => this.reloadData()}>Reload Data</Button>
                </Panel.Body>
              </Panel>
            </Col>
            <Col md={9}>
              <GridListChecked
                data={this.state.data}
                columns={columns}
                selection={this.state.selection}
                updateSelection={this.updateSelection}
                processingMessage={this.state.processingMessage}
              />
            </Col>
          </Row>
        </Grid>
      </Fragment>
    )
  }
}

export default CertificationInstructor