//validations for editPerson

import { validator, validatorPerson } from '../../libs/validation/validator.js'
import { required, isNumeric } from '../../libs/validation/rules.js'

export const fieldValidations = [
  // validator("phone", "Phone Number", required),
  validator("month", "Month", required, isNumeric),
  validator("day", "Day", required, isNumeric),
  validator("year", "Year", required, isNumeric),
  validator("givenName", "First Name", required),
  validator("surname", "Last Name", required),
  validator("email", "Email", required)
]

export const fieldValidationsPerson = [
  validatorPerson("address1", "Address 1", required),
  validatorPerson("city", "City", required),
  validatorPerson("state", "State", required),
  validatorPerson("postalCode", "Postal Code", required),
  validatorPerson("country", "Country", required),
  validatorPerson("phone", "Phone", required)
]