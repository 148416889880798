import React, { Component } from 'react';
import { Panel, Grid, Row, Col, Button, ControlLabel } from 'react-bootstrap';
import ValidatedFormGroup from '../../ValidatedFormGroup.js';
import ValidatedDateFields from '../../Generic/ValidatedDateFields/ValidatedDateFields.js';
import { runRules, notAllValid } from '../../../libs/validation/validator.js';
import { fieldValidations } from './validations.js';
import person from '../../../model/Person.js';
import update from 'immutability-helper';
import moment from 'moment';
import PropTypes from 'prop-types';

class ProtectedFieldsPersonEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      givenName: this.props.person.givenName,
      middleName: this.props.person.middleName,
      surname: this.props.person.surname,
      month: moment(this.props.person.birthdate).format('MM'),
      day: moment(this.props.person.birthdate).format('DD'),
      year: moment(this.props.person.birthdate).format('YYYY'),
      diverIdNumber: this.props.person.diverIdNumber || '',
      validationErrors: {},
    };
    this.state.validationErrors = runRules(this.state, fieldValidations);
  }

  componentDidUpdate = (prevProps) => {
    ///refresh our local state with the new props on change.
    if (this.props !== prevProps) {
      let newState = update(this.state, {
        givenName: { $set: this.props.person.givenName },
        middleName: { $set: this.props.person.middleName },
        surname: { $set: this.props.person.surname },
        month: { $set: moment(this.props.person.birthdate).format('MM') },
        day: { $set: moment(this.props.person.birthdate).format('DD') },
        year: { $set: moment(this.props.person.birthdate).format('YYYY') },
        diverIdNumber: { $set: this.props.person.diverIdNumber } || '',
      });
      this.setState(newState);
    }
  };

  getErrorFor = (field) => {
    return this.state.validationErrors[field] || '';
  };

  handleChange = (event) => {
    let newState = update(this.state, {
      [event.target.id]: { $set: event.target.value },
    });
    newState.validationErrors = runRules(newState, fieldValidations);
    this.setState(newState);
  };

  saveNameChanges = () => {
    let newPersonData = update(this.props.person.data, {
      givenName: { $set: this.state.givenName },
      middleName: { $set: this.state.middleName },
      surname: { $set: this.state.surname },
    });
    delete newPersonData.verificationSearch;
    delete newPersonData.fullName;
    var newPerson = new person(newPersonData);
    this.props.savePerson(newPerson);
  };

  saveBirthdateChanges = () => {
    var birthdate = moment(this.state.month + '/' + this.state.day + '/' + this.state.year, 'MM/DD/YYYY');
    let newPersonData = update(this.props.person.data, {
      birthdate: { $set: birthdate.format() },
    });
    var newPerson = new person(newPersonData);
    this.props.savePerson(newPerson);
  };

  saveDiverIdNumberChange = () => {
    let newPersonData = update(this.props.person.data, {
      diverIdNumber: { $set: this.state.diverIdNumber },
    });
    var newPerson = new person(newPersonData);
    this.props.savePerson(newPerson);
  };

  render() {
    return (
      <Grid fluid={true}>
        <Panel>
          <Panel.Heading>Change Name</Panel.Heading>
          <Panel.Body>
            <Row>
              <Col md={4}>
                <ValidatedFormGroup
                  id="givenName"
                  label="First Name"
                  showError={true}
                  errorText={this.getErrorFor('givenName')}
                  type="text"
                  value={this.state.givenName}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md={4}>
                <ValidatedFormGroup
                  id="middleName"
                  label="Middle Name"
                  showError={true}
                  errorText={this.getErrorFor('middleName')}
                  type="text"
                  value={this.state.middleName || ''}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md={4}>
                <ValidatedFormGroup
                  id="surname"
                  label="Last Name"
                  showError={true}
                  errorText={this.getErrorFor('surname')}
                  type="text"
                  value={this.state.surname}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
          </Panel.Body>
          <Panel.Footer>
            <Button type="button" onClick={() => this.saveNameChanges()}>
              Save Name Changes
            </Button>
          </Panel.Footer>
        </Panel>
        <Panel>
          <Panel.Heading>Change Birthdate</Panel.Heading>
          <Panel.Body>
            <ValidatedDateFields
              id_day="day"
              id_month="month"
              id_year="year"
              month={this.state.month}
              day={this.state.day}
              year={this.state.year}
              errors={this.state.validationErrors}
              handleChange={this.handleChange}
            />
          </Panel.Body>
          <Panel.Footer>
            <Button type="button" onClick={() => this.saveBirthdateChanges()}>
              Save Birthdate
            </Button>
          </Panel.Footer>
        </Panel>
        <Panel>
          <Panel.Heading>Change Diver ID Number</Panel.Heading>
          <Panel.Body>
            <ValidatedFormGroup
              id="diverIdNumber"
              label="Diver Id Number"
              showError={true}
              errorText={this.getErrorFor('diverIdNumber')}
              type="text"
              value={this.state.diverIdNumber}
              onChange={this.handleChange}
            />
          </Panel.Body>
          <Panel.Footer>
            <Button type="button" onClick={() => this.saveDiverIdNumberChange()}>
              Save Diver ID Number Change
            </Button>
          </Panel.Footer>
        </Panel>
      </Grid>
    );
  }
}

ProtectedFieldsPersonEditor.propTypes = {
  person: PropTypes.object.isRequired,
  savePerson: PropTypes.func.isRequired,
};
export default ProtectedFieldsPersonEditor;
