import React, { Component } from 'react'
import { Panel, Button, Grid, Row, Col, ControlLabel, FormGroup, FormControl, Label } from 'react-bootstrap'
import { Storage } from 'aws-amplify'
import ValidatedFormGroup from '../ValidatedFormGroup.js'
import Avatar from 'react-avatar'
import { runRules, notAllValid } from '../../libs/validation/validator.js'
import { fieldValidationsPerson } from './validations.js'
import person from '../../model/Person.js'
import update from 'immutability-helper'
import PropTypes from 'prop-types'
import moment from 'moment'
import { sleep } from '../../libs/utils.js'

//use for handling Diver Picture Uploads
const customPrefix = {
  public: 'diverPhotos/'
}

class EditPerson extends Component {

  _isMounted = false

  constructor(props){
    super(props)
    this.state = {
      person: update({}, {$set: this.props.person}),
      //image: 'https://s3.amazonaws.com/diverphotos-sei-portal/' + this.props.person.personId,
      imgCtl: 0,
      imageUploading: false,
      imageError: '',
      imageUpload: '',
      validationErrors: { }
    }

    this.state.validationErrors = runRules(this.state, fieldValidationsPerson)

    this.handleChange = this.handleChange.bind(this)
    this.getErrorFor = this.getErrorFor.bind(this)
    this.handleFileUploadChange = this.handleFileUploadChange.bind(this)
  }

  componentDidMount = async () => {
    this._isMounted = true
    await this.updateImage()
  }

  componentDidUpdate = async (prevProps) => {
    //refresh our local state with new props on change
    if(this.props !== prevProps){
      let newState = update(this.state, {
        person: {$set: this.props.person},
        imageUpload: {$set: ''}
      })
      this.setState(newState)
      await this.updateImage()
    }
  }

  componentWillUnmount(){
    this._isMounted = false
  }
  
  updateImage = async () => {
    var picURL = await this.props.person.getImage()
    if(this._isMounted === true){
      this.setState({
        image: picURL,
        imageUploading: false
      })
    }
  }

  handleFileUploadChange = (callback, event) => {
    this.setState({imageUploading: true, imageError: ''})
    console.log('changing image')
    if(event.target.files[0].size < 999999){
      try {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        this.setState({image: ''})
        var workingPerson = this.state.person
        reader.onload = async function(){
          var res = await workingPerson.saveImage(reader.result)
           callback(reader.result)
        }
        this.setState({imageUploading: false, imageError: ''})
      } catch (error) {
        alert('Photo upload failed \n' + error)
        this.setState({imageUploading: false})
      }
    } else {
      this.setState({
        imageError: 'Image must be less than 1MB',
        imageUploading: false
      })
    }

  }


  handleChange = (event) => {
    var newPerson = new person(this.state.person.data)

    newPerson.data[event.target.id] = event.target.value


    let newState = update(this.state, {
      person: {$set: newPerson}
    })
    newState.validationErrors = runRules(newState, fieldValidationsPerson);
    this.setState(newState)
  }

  getErrorFor = (field) =>{
    return this.state.validationErrors[field] || "";
  }



  render(){
    return (
      <Grid fluid={true}>
        <Row>
          <h2>{this.state.person.givenName + ' ' + this.state.person.surname}</h2>
        </Row>
        <Panel>
          <Panel.Heading>Birthdate</Panel.Heading>
          <Panel.Body>
            <ControlLabel>{moment(this.state.person.birthdate).format('MM/DD/YYYY')}</ControlLabel>
          </Panel.Body>
        </Panel>
        {
          this.state.person.diverIdNumber
          ?
          <Panel>
            <Panel.Heading>Diver ID Number</Panel.Heading>
            <Panel.Body>
              <ControlLabel>{this.state.person.diverIdNumber}</ControlLabel>
            </Panel.Body>
          </Panel>
          :
          null
        }
        <Panel>
          <Panel.Heading>Contact Information</Panel.Heading>
          <Panel.Body>
            <Row>
              <Col md={4}>
                <ControlLabel>{'  Email: ' + this.state.person.email}</ControlLabel>
              </Col>
              <Col md={4}>
                {
                  this.props.allowEmailChange
                  ?
                  <Button>Change Email</Button>
                  :
                  false
                }
              </Col>
              <Col md={4}>
                <ValidatedFormGroup
                  id='phone'
                  label='Phone'
                  autoFocus={true}
                  showError={true}
                  errorText={this.getErrorFor("phone")}
                  type='text'
                  value={this.state.person.phone || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
        <Panel>
          <Panel.Heading>Address Information</Panel.Heading>
          <Panel.Body>
            <Row>
              <Col md={12}>
                <ValidatedFormGroup
                  id='address1'
                  label='Address 1'
                  showError={true}
                  errorText={this.getErrorFor("address1")}
                  type='text'
                  value={this.state.person.address1 || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ValidatedFormGroup
                  id='address2'
                  label='Address 2'
                  showError={true}
                  errorText={this.getErrorFor("address2")}
                  type='text'
                  value={this.state.person.address2 || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <ValidatedFormGroup
                  id='city'
                  label='City'
                  showError={true}
                  errorText={this.getErrorFor("city")}
                  type='text'
                  value={this.state.person.city || ''}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md={4}>
                <ValidatedFormGroup
                  id='state'
                  label='State'
                  showError={true}
                  errorText={this.getErrorFor("state")}
                  type='text'
                  value={this.state.person.state || ''}
                  onChange={this.handleChange}
                />
              </Col>
              <Col md={4}>
                <ValidatedFormGroup
                  id='postalCode'
                  label='Postal Code'
                  showError={true}
                  errorText={this.getErrorFor("postalCode")}
                  type='text'
                  value={this.state.person.postalCode || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ValidatedFormGroup
                  id='country'
                  label='Country'
                  showError={true}
                  errorText={this.getErrorFor("country")}
                  type='text'
                  value={this.state.person.country || ''}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
        <Panel>
          <Row>
            <Col md={6} sm={6}>
              <FormGroup controlId='formControlFile'>
                <ControlLabel>Diver Photo</ControlLabel>
                <ControlLabel>Face Picture. No Dark Sunglasses or Hat. Max 1MB in size</ControlLabel>
                <FormControl type='file' onChange={this.handleFileUploadChange.bind(this, this.updateImage)} value={this.state.imageUpload}/>
                <Label bsStyle='danger'>{this.state.imageError}</Label>
              </FormGroup>
            </Col>
            <Col md={6} sm={6}>
              <Avatar key={this.state.imgCtl} src={this.state.image} />
            </Col>
          </Row>
        </Panel>
        <Row>
          <Col md={4}>
            <Button disabled={this.state.imageUploading || notAllValid(this.state.validationErrors)} onClick={() => this.props.savePerson(this.state.person)}>Save Changes</Button>
          </Col>
          <Col md={8}></Col>
        </Row>
      </Grid>
    )
  }
}

EditPerson.propTypes = {
  person: PropTypes.object.isRequired,
  savePerson: PropTypes.func.isRequired,
  allowEmailChange: PropTypes.bool.isRequired,
  editBirthday: PropTypes.bool
}
export default EditPerson
