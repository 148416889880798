import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal, Button, Grid, Row, Col, ControlLabel } from 'react-bootstrap';
import ValidatedFormGroup from '../ValidatedFormGroup.js';
import ValidatedDateFields from '../Generic/ValidatedDateFields/ValidatedDateFields.js'
import { runRules, notAllValid } from '../../libs/validation/validator.js';
import { fieldValidations } from './validations.js'
import update from 'immutability-helper';
import { diverVerified } from './actions.js';
import { ctl_showVerificationPanel } from '../../redux/control-actions.js'
import person from '../../model/Person.js';
import moment from 'moment';
import PropTypes from 'prop-types';
import './VerifyDiverModal.css'

const initialState = {
  diverIdNumber: '',
  givenName: '',
  surname: '',
  month: '',
  day: '',
  year: '',
  validationErrors: { }
}

const mapStateToProps = (state) => {
  return {
    // authState: state.authState,
    // authData: state.authData,
    visible: state.verification.ctl_showVerificationPanel,
    verification: state.verification
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showModal: (visible) => {dispatch(ctl_showVerificationPanel(visible))},
    diverVerified: (verified, certs, verifiedDiverIdNumber, givenName, surname) => {dispatch(diverVerified(verified, certs, verifiedDiverIdNumber, givenName, surname))}
  }
}

class VerifyDiverModal extends Component {
  constructor(props){
    super(props);
    this.state = initialState
    this.state.validationErrors = runRules(this.state, fieldValidations)
  }

  handleChange = (event) => {
    let newState = update(this.state, {
      [event.target.id]: {$set: event.target.value}
    });
    newState.validationErrors = runRules(newState, fieldValidations);
    this.setState(newState);
  }

  getErrorFor(field){
    return this.state.validationErrors[field] || "";
  }

  async checkVerification(){
    //construct birthdate as moment object
    var birthdate = moment(this.state.month + '/' + this.state.day + '/' + this.state.year, 'MM/DD/YYYY')
    //Check by name
    if(this.state.diverIdNumber === ''){
      console.log('checking by name')
      var result = await person.verifyDiver(this.state.givenName, this.state.surname, birthdate.format())
      if(result.verified === true){
        this.props.diverVerified(result.verified, result.certifications, result.diverIdNumber, this.state.givenName, this.state.surname)
        this.props.verifyCallback()
        this.props.showModal(false)
        this.setState(initialState)
      } else {
          this.props.diverVerified(result.verified, [], '')
          alert(result.msg)
      }
    } else {
      //chack by number
      console.log('checking by number')
      let result = await person.verifyDiver('','','',this.state.diverIdNumber)
      var localVerificationData = person.buildVerificationSearchData(this.state.givenName, this.state.surname, birthdate)
      if(result.verified === true && localVerificationData === result.verificationSearch){
        this.props.diverVerified(result.verified, result.certifications, result.diverIdNumber, this.state.givenName, this.state.surname)
        this.props.verifyCallback()
        this.props.showModal(false)
        this.setState(initialState)
      } else if(result.verified === true && localVerificationData !== result.verificationSearch){
        this.props.diverVerified(false, [], '')
        alert('You provided a valid diver ID, \nhowever your name or birth date does not match our records. \nPlease try again or contact the agency')
      } else {
        this.props.diverVerified(result.verified, [], '')
        alert(result.msg)
      }
    }
    //Accepts a prop function to call once the verification process is complete


  }
  render(){
    return (
      <div>
        <Modal show={this.props.visible} onHide={() => this.props.showModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Diver Verification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid={true}>
              <Row>
                <Col md={12}>
                  <p>Enter the required information exactly as it appears on your certification card.</p>
                  <form>
                    <ValidatedFormGroup
                      id='diverIdNumber'
                      label='Diver ID Number'
                      showError={true}
                      errorText={this.getErrorFor("diverIdNumber")}
                      autoFocus
                      type='text'
                      value={this.state.diverIdNumber}
                      onChange={this.handleChange}
                    />
                    <ValidatedFormGroup
                      id='givenName'
                      label='First Name'
                      showError={true}
                      errorText={this.getErrorFor("givenName")}
                      type='text'
                      value={this.state.givenName}
                      onChange={this.handleChange}
                    />
                    <ValidatedFormGroup
                      id='surname'
                      label='Last Name'
                      showError={true}
                      errorText={this.getErrorFor("surname")}
                      type='text'
                      value={this.state.surname}
                      onChange={this.handleChange}
                    />
                  </form>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ControlLabel>Birthdate (MM/DD/YYYY)</ControlLabel>
                </Col>
              </Row>
              {/* <Row>
                <Col xs={4} sm={4} md={4}>
                  <ValidatedFormGroup
                    id='month'
                    label='Month'
                    showError={true}
                    errorText={this.getErrorFor("month")}
                    type='text'
                    value={this.state.month}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col xs={4} sm={4} md={4}>
                  <ValidatedFormGroup
                    id='day'
                    label='Day'
                    showError={true}
                    errorText={this.getErrorFor("day")}
                    type='text'
                    value={this.state.day}
                    onChange={this.handleChange}
                  />
                </Col>
                <Col xs={4} sm={4} md={4}>
                  <ValidatedFormGroup
                    id='year'
                    label='Year'
                    showError={true}
                    errorText={this.getErrorFor("year")}
                    type='text'
                    value={this.state.year}
                    onChange={this.handleChange}
                  />
                </Col>
              </Row> */}
              <ValidatedDateFields
                id_month='month'
                id_day='day'
                id_year='year'
                month={this.state.month}
                day={this.state.day}
                year={this.state.year}
                errors={this.state.validationErrors}
                handleChange={this.handleChange}
              />
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button type='button' disabled={notAllValid(this.state.validationErrors)} onClick={() => this.checkVerification()}>Verify</Button>
           <Button type='button' onClick={() => this.props.showModal(false)}>Close</Button>
         </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
VerifyDiverModal.propTypes = {
  // authState: PropTypes.string.isRequired,
  // authData: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  verification: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  diverVerified: PropTypes.func.isRequired,
  verifyCallback: PropTypes.func.isRequired

}
export default connect(mapStateToProps,mapDispatchToProps)(VerifyDiverModal)
