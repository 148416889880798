//Container conponemt for finding a diver. Requires a callback function which
//controls modal visability and accepts the returned person.
import React, { Component } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import FindByDiverId from './FindByDiverId.js'
import FindByDiverName from './FindByDiverName.js'

class FindByDiverUiModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      key: 1
    }
  }

  handleSelect = (key) => {
    this.setState({key: key})
  }

  render(){
    return (
      <Modal show={this.props.visible} onHide={() => this.props.callback(false)}>
        <Modal.Header>
          <Modal.Title>{this.props.title || 'Find Diver'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs id='find-diver-tabs' defaultActiveKey={1} onSelect={this.handleSelect}>
            <Tab eventKey={1} title='Find By Diver Id'>
              <FindByDiverId callback={this.props.callback} />
            </Tab>
            <Tab eventKey={2} title='Find By Name'>
              <FindByDiverName callback={this.props.callback} />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

FindByDiverUiModal.propTypes = {
  callback: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}

export default FindByDiverUiModal

