import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel, Button, Grid, Row, Col, ControlLabel } from 'react-bootstrap';
import ValidatedFormGroup from '../ValidatedFormGroup.js';
import ValidatedDateFields from '../Generic/ValidatedDateFields/ValidatedDateFields.js';
import { runRules, notAllValid } from '../../libs/validation/validator.js';
import { fieldValidations } from './validations.js';
import person from '../../model/Person.js';
import EditPerson from './EditPerson.js';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import uuid from 'uuid';
import moment from 'moment';

const mapStateToProps = (state) => {
  return {
    creator: state.person,
  };
};
class CreatePerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFirstStage: true,
      showSecondStage: false,
      validationErrors: {},
    };
    this.state.validationErrors = runRules(this.state, fieldValidations);

    this.setupPerson = this.setupPerson.bind(this);
    this.savePerson = this.savePerson.bind(this);
  }

  setupPerson = (input) => {
    //called by firstStageFields to setup the person Object
    console.log(input);
    var birthdate = moment(input.month + '/' + input.day + '/' + input.year, 'MM/DD/YYYY');
    console.log(birthdate);
    var data = {
      personId: uuid(),
      givenName: input.givenName.trim(),
      middleName: input.middleName.trim() || '',
      surname: input.surname.trim(),
      birthdate: birthdate.format(),
      email: input.email.trim(),
      defaultRegion: this.props.creator.defaultRegion,
    };
    var newPerson = new person(data);

    console.log(newPerson);
    var newState = update(this.state, {
      person: { $set: newPerson },
      showFirstStage: { $set: false },
      showSecondStage: { $set: true },
    });
    this.setState(newState);
  };

  savePerson = async (person) => {
    try {
      await person.save();
      this.props.savePersonCallback(person);
    } catch (error) {
      alert('Save Failed \n' + error);
    }
  };

  render() {
    return (
      <Grid fluid={true}>
        <Row>
          <Col md={12}>
            {this.state.showFirstStage ? <FirstStageFields continueCallback={this.setupPerson} /> : null}
            {this.state.showSecondStage ? (
              <EditPerson person={this.state.person} savePerson={this.savePerson} allowEmailChange={false} />
            ) : null}
          </Col>
        </Row>
      </Grid>
    );
  }
}

class FirstStageFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      givenName: '',
      surname: '',
      middleName: '',
      email: '',
      day: '',
      month: '',
      year: '',
      validationErrors: {},
    };
    this.state.validationErrors = runRules(this.state, fieldValidations);
  }

  getErrorFor = (field) => {
    return this.state.validationErrors[field] || '';
  };

  handleChange = (event) => {
    let newState = update(this.state, {
      [event.target.id]: { $set: event.target.value },
    });
    newState.validationErrors = runRules(newState, fieldValidations);
    this.setState(newState);
  };

  render() {
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>
            <h3>Basic Information</h3>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Grid fluid={true}>
            <Row>
              <Col md={12}>
                <ControlLabel>Enter Full Legal Name. No appreviations or Nicknames</ControlLabel>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ValidatedFormGroup
                  id="givenName"
                  label="First Name"
                  showError={true}
                  errorText={this.getErrorFor('givenName')}
                  type="text"
                  value={this.state.givenName}
                  onChange={this.handleChange}
                />
                <ValidatedFormGroup
                  id="middleName"
                  label="Middle Name"
                  showError={true}
                  errorText={this.getErrorFor('middleName')}
                  type="text"
                  value={this.state.middleName || ''}
                  onChange={this.handleChange}
                />
                <ValidatedFormGroup
                  id="surname"
                  label="Last Name"
                  showError={true}
                  errorText={this.getErrorFor('surname')}
                  type="text"
                  value={this.state.surname}
                  onChange={this.handleChange}
                />
                <ValidatedFormGroup
                  id="email"
                  label="Email"
                  showError={true}
                  errorText={this.getErrorFor('email')}
                  type="text"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ControlLabel>Birthdate (MM/DD/YYYY)</ControlLabel>
              </Col>
            </Row>
            <ValidatedDateFields
              id_day="day"
              id_month="month"
              id_year="year"
              month={this.state.month}
              day={this.state.day}
              year={this.state.year}
              errors={this.state.validationErrors}
              handleChange={this.handleChange}
            />
            {/* <Row>
              <Col xs={4} sm={4} md={4}>
                <ValidatedFormGroup
                  id='month'
                  label='Month'
                  showError={true}
                  errorText={this.getErrorFor("month")}
                  type='text'
                  value={this.state.month}
                  onChange={this.handleChange}
                />
              </Col>
              <Col xs={4} sm={4} md={4}>
                <ValidatedFormGroup
                  id='day'
                  label='Day'
                  showError={true}
                  errorText={this.getErrorFor("day")}
                  type='text'
                  value={this.state.day}
                  onChange={this.handleChange}
                />
              </Col>
              <Col xs={4} sm={4} md={4}>
                <ValidatedFormGroup
                  id='year'
                  label='Year'
                  showError={true}
                  errorText={this.getErrorFor("year")}
                  type='text'
                  value={this.state.year}
                  onChange={this.handleChange}
                />
              </Col>
            </Row> */}
          </Grid>
        </Panel.Body>
        <Panel.Footer>
          <Button
            type="button"
            disabled={notAllValid(this.state.validationErrors)}
            onClick={() => this.props.continueCallback(this.state)}
          >
            Continue
          </Button>
        </Panel.Footer>
      </Panel>
    );
  }
}

CreatePerson.propTypes = {
  savePersonCallback: PropTypes.func.isRequired,
};

FirstStageFields.propTypes = {
  continueCallback: PropTypes.func.isRequired,
};
export default connect(mapStateToProps)(CreatePerson);
