import React from 'react'
import { Panel} from 'react-bootstrap'
import moment from 'moment'

const AcceptanceViewer = (props) => {
    return(
        <Panel bsStyle='success'>
            <Panel.Heading><h3>Agreement Accepted</h3></Panel.Heading>
            <Panel.Body>
                {moment(props.acceptDate).format('MM/DD/YYYY')}
            </Panel.Body>
        </Panel>
    )
}
export default AcceptanceViewer