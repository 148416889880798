import React, { Component, Fragment } from "react";
import { withAuthenticator }  from './components/withAuthenticator/withAuthenticator.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { globalSetPerson, refreshAuthData, setAllowedCertifications } from './redux/global/actions.js';
import Routes from './routes.js';
import ConnectPerson from './components/ConnectPerson/ConnectPerson.js'
import person from './model/Person.js';
import certificationOffering from './model/CertificationOffering.js'
import { checkRole, roles } from './libs/groups.js'
import _ from 'lodash'
import "./SecuredApp.css";

const mapStateToProps = (state) => {
  return {
    authState: state.authState,
    authData: state.authData,
    person: state.person
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
    setPerson: (person) => {dispatch(globalSetPerson(person))},
    refreshAuthData: () => {dispatch(refreshAuthData())},
    setAllowedCertifications: (allowedCerts) => {dispatch(setAllowedCertifications(allowedCerts))}
  }
}


class SecuredApp extends Component {

  constructor(props){
    super(props)

    this.setupLoggedinPerson = this.setupLoggedinPerson.bind(this)
  }

  async componentDidMount(){
    await this.setupLoggedinPerson()
  }

  async componentDidUpdate(prevProps){
    if(prevProps !== this.props){
      await this.setupLoggedinPerson()
    }
  }

  async setupLoggedinPerson(){
    // if(this.props.authState === 'signedIn' && !_.has(this.props.authData, 'attributes')){
    //   console.log('we dont have attributes');
    //   this.props.refreshAuthData()
    // }
    if(this.props.authState === 'signedIn'  && _.has(this.props.authData.signInUserSession.idToken.payload, 'profile') && this.props.person === null){
      var newPerson = await person.findByIdentityId()
      this.props.setPerson(newPerson)
    }
    if(this.props.authState === 'signedIn' && this.props.person &&
      (
        checkRole(roles.app_instructors_role, this.props.authData.signInUserSession.idToken.payload['cognito:groups']) || 
        checkRole(roles.app_admin_region_role, this.props.authData.signInUserSession.idToken.payload['cognito:groups']) || 
        checkRole(roles.app_admin_region_role, this.props.authData.signInUserSession.idToken.payload['cognito:groups'])
      )
    ){
      var allowedCerts = await certificationOffering.getListOfOfferings()
      this.props.setAllowedCertifications(allowedCerts)
    }
  }



  render() {
    return (
      <Fragment>
        {
          _.has(this.props.authData.signInUserSession.idToken.payload, 'profile')
          ?
          <Routes {...this.props}/>
          :
          <ConnectPerson />
        }
      </Fragment>
    );
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(App);
export default withRouter(withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(SecuredApp), {includeGreetings: true}))
//export default App
