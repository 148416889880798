import * as ErrorMessages from './errorMessages.js'

export const required = (text) => {
  if (text) {
    return null;
  } else {
    return ErrorMessages.isRequired;
  }
};

export const minLength = (length) => {
  return (text) => {
    return text.length === length ? null : ErrorMessages.minLength(length);
  };
};

export const mustMatch = (field, fieldName) => {
  return (text, state) => {
    return state[field] === text ? null : ErrorMessages.mustMatch(fieldName);
  };
};

export const isNumeric = (value, fieldName) => {
  return (text) => {
    return isNaN(value) === false ? null : ErrorMessages.mustBeNumeric(fieldName);
  }
}

export const yearIsReasonable = (value) => {
  if(value > 1900){
    return null
  } else {
    return ErrorMessages.invalidYear()
  }
}
