import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import RouteNavItem from '../RouteNavItem.js';
import { connect } from 'react-redux';
import { checkRole, roles } from '../../libs/groups.js';

const mapStateToProps = (state) => {
  return {
    authState: state.authState,
    authData: state.authData,
    profile: state.profile,
  };
};
class MainMenu extends Component {
  render() {
    return (
      <Fragment>
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">Scuba Educators</Link>
            </Navbar.Brand>
          </Navbar.Header>
          <Navbar.Header>
            <Nav>
              {/* {
                this.props.authState === 'signedIn'
                ?
                <NavDropdown eventKey={0} key={0} title='Diver Resources' id='main-nav-dropdown'>
                  <RouteNavItem key={0.1} href='/editmyperson'>Edit My Profile</RouteNavItem>
                  <RouteNavItem key={0.2} href='/mycertifications'>My Certifications</RouteNavItem>
                </NavDropdown>
                :
                null
              } */}
            </Nav>
          </Navbar.Header>
          {/* <Navbar.Collapse> */}
          <Nav>
            {this.props.authState === 'signedIn' &&
              this.props.authData.signInUserSession.idToken.payload['cognito:groups'] &&
              checkRole(
                roles.app_instructors_role,
                this.props.authData.signInUserSession.idToken.payload['cognito:groups']
              )
              ? [
                <NavDropdown eventKey={1} key={1} title="Instructor Resources" id="main-nav-dropdown">
                  <RouteNavItem key={1.1} href="/instructor/certify">
                    Certify
                    </RouteNavItem>
                  <RouteNavItem key={1.2} href="/instructor/certify/leader">
                    Certify Leader
                    </RouteNavItem>
                  <RouteNavItem key={1.3} href="/instructor/certifications/status">
                    Certification Status
                    </RouteNavItem>
                  <RouteNavItem key={1.4} href="/instructor/downloads">
                    Downloads
                    </RouteNavItem>
                </NavDropdown>,
              ]
              : null}
            {this.props.authState === 'signedIn' &&
              this.props.authData.signInUserSession.idToken.payload['cognito:groups'] &&
              checkRole(
                roles.app_admin_region_role,
                this.props.authData.signInUserSession.idToken.payload['cognito:groups']
              )
              ? [
                <Fragment key={999}>
                  <NavDropdown eventKey={2} key={2} title="Certifications" id="main-nav-dropdown">
                    <RouteNavItem key={2.1} href="/admin/certification/process">
                      Process Certifications
                      </RouteNavItem>
                    <RouteNavItem key={2.2} href="/admin/certification">
                      Record Certification
                      </RouteNavItem>
                    <RouteNavItem key={2.3} href="/admin/certification/instructor">
                      Certifications By Instructor
                      </RouteNavItem>
                    <RouteNavItem key={2.4} href="/admin/certification/batch">
                      Certification Batch Manager
                      </RouteNavItem>
                  </NavDropdown>
                  <NavDropdown eventKey={3} key={3} title="Divers" id="main-nav-dropdown">
                    <RouteNavItem key={3.1} href="/admin/person/edit">
                      Edit Diver Information
                      </RouteNavItem>
                    <RouteNavItem key={3.2} href="/admin/person/create">
                      Create a New Diver Record
                      </RouteNavItem>
                  </NavDropdown>
                </Fragment>,
              ]
              : null}
            <RouteNavItem href="/verify">Diver Verification</RouteNavItem>
          </Nav>
          {/* </Navbar.Collapse> */}
        </Navbar>
      </Fragment>
    );
  }
}
export default connect(mapStateToProps)(MainMenu);
