import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'
import Home from "./components/Home/Home.js";
import NotFound from "./components/NotFound/NotFound.js";
import Certify from './components/Certify/Certify.js';
import InstructorCertStatusList from './components/InstructorCertStatusList/InstructorCertStatusList.js'
import Test from './components/Test/Test.js';
import About from './components/About/About.js';
import VerifyDiverPage from './components/VerifyDiverPage/VerifyDiverPage.js'
import CertificationInstructor from './components/Admin/CertificationInstructor/CertificationInstructor.js'
import CertificationProcessing from './components/Admin/CertificationProcessing/CertificationProcessing.js'
import CertificationBatchProcessing from './components/Admin/CertificationBatchProcessing/CertificationBatchProcessing.js'
import EnterCertificationForInstructor from './components/Admin/EnterCertificationForInstructor/EnterCertificationForInstructor.js'
import PersonEditor from './components/Admin/PersonEditor/PersonEditor.js'
import PureCreatePerson from './components/EditPerson/PureCreatePerson.js'
import EditMyPerson from './components/EditPerson/EditMyPerson.js'
import MyCertifications from './components/PersonInfoPanel/MyCertifications.js'
import InstructorDownloads from './components/InstructorDownloads/InstructorDownloads.js'
import CertifyLeader from './components/CertifyLeader/CertifyLeader.js'
import { checkRole, roles } from './libs/groups.js'

const mapStateToProps = (state) => {
  return {
    authState: state.authState,
    authData: state.authData,
    profile: state.profile
  }
}

const routes = (props) =>
  <FragmentSupportingSwitch>
    <Route path="/" exact render={(props) => <Home {...props} />} />
    <Route path="/about" exact render={(props) => <About {...props} />} />
    {/* <Route path='/test' exact render={(props) => <Test {...props} />} /> */}
    {/* <Route path='/editmyperson' exact render={(props) => <EditMyPerson {...props} /> } /> */}
    <Route path='/mycertifications' exact render={(props) => <MyCertifications {...props} />} />

    {/* Routes for instructors only */}
    {
      props.authState &&
        props.authState === 'signedIn' &&
        props.authData.signInUserSession.idToken.payload['cognito:groups'] &&
        checkRole(roles.app_instructors_role, props.authData.signInUserSession.idToken.payload['cognito:groups'])
        ?
        <Fragment>
          <Route path='/instructor/certify' exact component={Certify} />
          <Route path='/instructor/certifications/status' exact component={InstructorCertStatusList} />
          <Route path='/instructor/downloads' exact render={(props) => <InstructorDownloads {...props} />} />
          <Route path='/instructor/certify/leader' exact component={CertifyLeader} />
        </Fragment>
        :
        null
    }
    {/* Routes for regional Admins */}
    {
      props.authState &&
        props.authState === 'signedIn' &&
        props.authData.signInUserSession.idToken.payload['cognito:groups'] &&
        checkRole(roles.app_admin_region_role, props.authData.signInUserSession.idToken.payload['cognito:groups'])
        ?
        <Fragment>
          <Route path='/admin/certification/instructor' exact component={CertificationInstructor} />
          <Route path='/admin/certification/process' exact component={CertificationProcessing} />
          <Route path='/admin/certification/batch' exact component={CertificationBatchProcessing} />
          <Route path='/admin/certification' exact component={EnterCertificationForInstructor} />
          <Route path='/admin/person/edit' exact component={PersonEditor} />
          <Route path='/admin/person/create' exact component={PureCreatePerson} />
        </Fragment>
        :
        null
    }
    {/* <Route path='/verify' exact component={VerifyDiverPage} /> */}
    { /* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </FragmentSupportingSwitch>;

// react-router doesn't have support for React fragments in <Switch />. This component
// wraps react-router's <Switch /> so that it gets fragment support.
//
// https://github.com/ReactTraining/react-router/issues/5785
const FragmentSupportingSwitch = ({ children }) => {
  const flattenedChildren = [];
  flatten(flattenedChildren, children);
  return React.createElement.apply(React, [Switch, null].concat(flattenedChildren));
}

function flatten(target, children) {
  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child.type === Fragment) {
        flatten(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
}

export default connect(mapStateToProps)(routes)