export const HANDLE_AUTH_STATE_CHANGE = 'HANDLE_AUTH_STATE_CHANGE'

export const CTL_SHOW_VERIFICATION_PANEL  = 'CTL_SHOW_VERIFICATION_PANEL'

export const DIVER_VERIFIED = 'DIVER_VERIFIED'

export const GLOBAL_SET_PERSON = 'GLOBAL_SET_PERSON'

export const GLOBAL_REFRESH_AUTHDATA = 'GLOBAL_REFRESH_AUTHDATA'

export const SET_ALLOWED_CERTIFICATIONS = 'SET_ALLOWED_CERTIFICATIONS'