import React, { Component } from "react";
import { connect } from 'react-redux';
import "./Home.css";
import {Grid} from 'react-bootstrap'
import { ctl_showVerificationPanel } from '../../redux/control-actions.js';
import person from '../../model/Person.js';

const mapStateToProps = (state) => {
  return {
    authState: state.authState,
    authData: state.authData,
    person: state.person
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showVerification: (visible) => {dispatch(ctl_showVerificationPanel(visible))},
    diverVerified: (diverVerified, diverCertifications) => {dispatch(diverVerified(diverVerified, diverCertifications))}
  }
}

class Home extends Component {

  async componentDidMount(){
    //await this.test()
  }

  render() {
    // let apiName = 'dev-diverportal-api';
    // let path = '/person/diver/100100';
    // API.get(apiName, path).then(response => {
    //     console.log(response)
    // }).catch(error => {
    //     console.log('cathing error')
    //     console.log(error.response)
    // })

    return (
      <div className="Home">
        <div className="lander">
          <Grid>

            <h1>Scuba Educators</h1>
            <p>Welcome Instructors! This system replaces former SEI and PDIC certification portals.</p>
            <p>Click on Instructor Resources above to get started</p>
            <h2>New Process for leader certification:</h2>
            <p>You now have a Certify Leader option under the Certify menu. This new form is used to submit a leader certification.</p>
            <p>Remember, in an effort to make sure all our dive leaders are being compensated for their time, dive masters no longer pay renewals. This provides instructors with an opportunity to conduct crossover development courses for dive masters. Just be sure they meet our exit standards and input them into the form. They only need to pay $100 for their new leader kit.</p>
            <p>Leadership certification fee for Assistant Instructor and above is $100 plus their membership, and includes a new leader kit</p>
            {/* <p>A simple note taking app</p> */}
          </Grid>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

;
