//reducers for diver verification modal
import update from 'immutability-helper'

export const ctl_showVerificationPanel = (state, action) => {
  let newState = update(state, {verification: {
    ctl_showVerificationPanel: {$set: action.visState}
  }});
  return newState
}

export const diverVerified = (state, action) => {
  let newState = update(state, {verification: {
    diverVerified: {$set: action.diverVerified},
    diverCertifications: {$set: action.diverCertifications},
    verifiedDiverIdNumber: {$set: action.verifiedDiverIdNumber},
    givenName: {$set: action.givenName},
    surname: {$set: action.surname}
  }});
  return newState
}
