//data model for certification objects
import { apiName } from '../config.js';
import { API } from 'aws-amplify';
import _ from 'lodash';
import person from './Person.js';

var Certification = function (input) {
  //load input  into the protected data object.
  this.data = input;

  if (!_.has(this.data, 'expires')) {
    this.data.expires = false;
  }

  Object.defineProperty(this, 'certId', {
    get: function () {
      return this.data.certId;
    },
    set: function (value) {
      this.data.certId = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'certName', {
    get: function () {
      return this.data.certName;
    },
    set: function (value) {
      this.data.certName = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'certLevel', {
    get: function () {
      return this.data.certLevel;
    },
    set: function (value) {
      this.data.certLevel = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'certDate', {
    get: function () {
      return this.data.certDate.substring(0, this.data.certDate.indexOf('T'));
    },
    set: function (value) {
      this.data.certDate = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'instructorId', {
    get: function () {
      return this.data.instructorId;
    },
    set: function (value) {
      this.data.instructorId = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'instructorFirstName', {
    get: function () {
      return this.data.instructorFirstName;
    },
    set: function (value) {
      this.data.instructorFirstName = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'instructorLastName', {
    get: function () {
      return this.data.instructorLastName;
    },
    set: function (value) {
      this.data.instructorLastName = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'submitterIdentityId', {
    get: function () {
      return this.data.submitterIdentityId;
    },
    set: function (value) {
      this.data.submitterIdentityId = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'cardReturn', {
    get: function () {
      return this.data.cardReturn;
    },
    set: function (value) {
      this.data.cardReturn = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'status', {
    get: function () {
      return this.data.status;
    },
    set: function (value) {
      this.data.status = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'certifiedPersonId', {
    get: function () {
      return this.data.certifiedPersonId;
    },
    set: function (value) {
      this.data.certifiedPersonId = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'expires', {
    get: function () {
      return this.data.Expires;
    },
    set: function (value) {
      this.data.expires = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'expirationDate', {
    get: function () {
      return this.data.expirationDate.substring(0, this.data.expirationDate.indexOf('T'));
    },
    set: function (value) {
      this.data.expirationDate = value;
    },
    enumerable: true,
  });

  Object.defineProperty(this, 'createdOn', {
    value: this.data.createdOn,
    writable: false,
    enumerable: true,
  });

  Object.defineProperty(this, 'lastModified', {
    value: this.data.lastModified,
    writable: false,
    enumerable: true,
  });
  //Make the data object private
  Object.defineProperty(this, 'data', {
    enumerable: true,
  });
};

Certification.prototype.getThisDiver = async function () {
  return await person.findById(this.data.certifiedPersonId);
};

Certification.prototype.getThisInstructor = async function () {
  return await person.findById(this.data.instructorId);
};

Certification.prototype.renew = async function (newExpirationDate = null) {
  var data = { certificationId: this.data.certId };
  var path = '/certification/renew/';

  if (newExpirationDate !== null) {
    data.expirationDate = newExpirationDate;
  }
  var myInit = {
    body: { ...data },
  };

  try {
    var response = await API.post(apiName, path, myInit);
    return response;
  } catch (e) {
    console.log(e);
    alert(e);
  }
};

Certification.prototype.reprint = async function () {
  this.data.status = 'reprint';
  return await this.save();
};

Certification.prototype.save = async function () {
  var data = this.data;
  var path = '/certification';
  var myInit = {
    body: { data },
  };
  try {
    //Get the associated person
    //find the certification record. Delete and replace with this one.
    var certPerson = await this.getThisDiver();
    _.remove(certPerson.certifications, (c) => {
      return c.certId === data.certId;
    });
    certPerson.certifications.push(this.data);
    if (this.data.certLevel >= 300 && this.data.status === 'complete') {
      certPerson.isInstructor = true;
    }
    if (this.data.certLevel >= 300 && this.data.status === 'expired') {
      certPerson.isInstructor = false;
    }
    await certPerson.save();
    //save the certification object.
    var response = await API.post(apiName, path, myInit);
    return new Certification(response);
  } catch (e) {
    console.log(e);
    alert(e);
  }
};

Certification.findById = async function (certId) {
  var path = '/certification/' + certId;
  try {
    var response = await API.get(apiName, path);
    if (response.err) {
      throw response.err;
    }
    if (response === 'no data') {
      return 'No Records Found';
    }
    var newCert = new Certification(response);
    return newCert;
  } catch (e) {
    alert(e);
  }
};
Certification.listCertsForThisInstructor = async function () {
  var path = '/instructor/certifications/list';

  try {
    var response = await API.get(apiName, path);
    if (response.err) {
      throw response.err;
    }
    if (response === 'no data') {
      return 'No Records Found';
    }
    var certs = [];
    for (var i = 0; i < response.length; i++) {
      certs.push(new Certification(response[i]));
    }
    return certs;
  } catch (e) {
    alert(e);
  }
};

Certification.listCertsByStatus = async function (status) {
  var path = '/admin/certifications/list/status/' + status;

  try {
    var response = await API.get(apiName, path);
    if (response.err) {
      throw response.err;
    }
    if (response === 'no data') {
      return 'No Records Found';
    }
    var certs = [];
    for (var i = 0; i < response.length; i++) {
      certs.push(new Certification(response[i]));
    }
    return certs;
  } catch (e) {
    alert(e);
  }
};
Certification.listCertsByStatusAndInstructor = async function (status, instructorId) {
  var path = '/admin/certifications/list/' + instructorId + '/' + status;

  try {
    var response = await API.get(apiName, path);
    if (response.err) {
      return response.err;
    }
    if (response === 'no data') {
      return 'No Records Found';
    }
    var certs = [];
    for (var i = 0; i < response.length; i++) {
      certs.push(new Certification(response[i]));
    }
    return certs;
  } catch (e) {
    alert(e);
  }
};
export default Certification;
