import certification from '../Certification.js';
import moment from 'moment';
import _ from 'lodash';
const certBatchPrintFileExporter = async function (batch, cardType = 0){
    //responsible for returning an array of objects used to render the Certification Batch Data CSV file 
    // for download to be used for loading to the CRM card model.
    //takes a fully populated certification batch for input
console.log('in crm view')
var data = [];

  for (var i = 0; i < batch.certifications.length; i++) {
    let cert = await certification.findById(batch.certifications[i]);
    console.log(cert);
    console.log('name');
    console.log(cert.certName);
    let student = await cert.getThisDiver();
    let instructor = await cert.getThisInstructor();
    console.log('===Data view function===');
    var item = {
      diverGivenName: student.givenName,
      diverSurname: student.surname,
      diverIdNumber: student.diverIdNumber,
      certDate: moment(cert.certDate).format('DD-MMM-YYYY'),
      instructorGivenName: instructor.givenName,
      instructorSurname: instructor.surname,
      instructorIdNumber: instructor.diverIdNumber,
      birthdate: moment(student.birthdate).format('DD-MMM-YYYY'),
      certName: cert.certName,
      photoURL: 'http://diverphotos-sei-portal.s3-website-us-east-1.amazonaws.com/' + student.personId,
      createdAt:  moment().format('YYYY-MM-DD'),
      updatedAt:  moment().format('YYYY-MM-DD'),
      cardType: cardType
    };
    if (cert.cardReturn === 'student') {
      item.shippingName = `${student.givenName} ${student.surname}`
      item.shippingAddress1 = student.address1;
      item.shippingAddress2 = student.address2;
      item.shippingCity = student.city;
      item.shippingState = student.state;
      item.shippingPostalCode = student.postalCode;
      item.shippingCountry = student.country;
    } else {
      item.shippingName = `${instructor.givenName} ${instructor.surname}`
      item.shippingAddress1 = instructor.address1;
      item.shippingAddress2 = instructor.address2;
      item.shippingCity = instructor.city;
      item.shippingState = instructor.state;
      item.shippingPostalCode = instructor.postalCode;
      item.shippingCountry = instructor.country;
    }

    console.log(item);
    data.push(item);
  }
  return data;
};

const headers = [
  'diverGivenName',
  'diverSurname',
  'diverIdNumber',
  'certDate',
  'birthdate',
  'certName',
  'photoURL',
  'instructorGivenName',
  'instructorSurname',
  'instructorIdNumber',
  'createdAt',
  'updatedAt',
  'cardType',
  'shippingName',
  'shippingAddress1',
  'shippingAddress2',
  'shippingCity',
  'shippingState',
  'shippingPostalCode',
  'shippingCountry',

];

export default {certBatchPrintFileExporter, headers}