//used within the find diver modal to find by name.
import React, { Component } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import ValidatedFormGroup from '../ValidatedFormGroup.js';
import GridListRadio from '../lists/GridListRadio.js';
import { columns } from './gridColumns.js';
import { runRules, notAllValid } from '../../libs/validation/validator.js';
import person from '../../model/Person.js';
import { nameValidations } from './validations.js';
import update from 'immutability-helper';
import _ from 'lodash';
import PropTypes from 'prop-types';

class FindByDiverName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selection: -1,
      processingMessage: 'Awaiting Data',
      validationErrors: {},
    };
    this.state.validationErrors = runRules(this.state, nameValidations);
  }

  loadData = async () => {
    if (this.state.givenName && this.state.givenName !== '' && this.state.surname && this.state.surname !== '') {
      this.setState({ processingMessage: 'loading...', selection: -1 });
      var people = [];
      people = await person.findByName(this.state.givenName, this.state.surname);

      console.log(people);
      if (people !== 'no Records Found') {
        var data = people.map((i, idx) => {
          return { ...i, _id: idx };
        });
        this.setState({
          data: data,
          processingMessage: 'Awaiting Data',
        });
      } else {
        this.setState({ processingMessage: 'No Data' });
      }
    }
  };

  getErrorFor = (field) => {
    return this.state.validationErrors[field] || '';
  };

  handleChange = (event) => {
    let newState = update(this.state, {
      [event.target.id]: { $set: event.target.value },
    });
    newState.validationErrors = runRules(newState, nameValidations);
    this.setState(newState);
  };

  updateSelection = (selection) => {
    this.setState({ selection: selection });
  };

  getSelectedPerson = async (id = this.state.selection) => {
    //returns the person object for the selected person in the grid
    var index = _.findIndex(this.state.data, (i) => {
      return i._id === id;
    });
    var foundPerson = await person.findById(this.state.data[index].data.personId);
    return foundPerson;
  };

  handleComplete = async () => {
    var selectedPerson = await this.getSelectedPerson(this.state.selection);
    this.props.callback(false, selectedPerson);
  };

  render() {
    return (
      <Grid fluid={true}>
        <Row>
          <Col md={6}>
            <ValidatedFormGroup
              id="givenName"
              label="First Name"
              showError={true}
              errorText={this.getErrorFor('givenName')}
              type="text"
              autoFocus
              value={this.state.givenName || ''}
              onChange={this.handleChange}
            />
          </Col>
          <Col md={6}>
            <ValidatedFormGroup
              id="surname"
              label="Last Name"
              showError={true}
              errorText={this.getErrorFor('surname')}
              type="text"
              value={this.state.surname || ''}
              onChange={this.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <GridListRadio
              data={this.state.data}
              columns={columns}
              selection={this.state.selection}
              updateSelection={this.updateSelection}
              processingMessage={this.state.processingMessage}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Button type="button" disabled={notAllValid(this.state.validationErrors)} onClick={() => this.loadData()}>
              Search
            </Button>
          </Col>
          <Col md={3}></Col>
          <Col md={3}>
            <Button type="button" disabled={this.state.selection <= -1} onClick={() => this.handleComplete()}>
              Select
            </Button>
          </Col>
          <Col md={3}>
            <Button type="button" onClick={() => this.props.callback(false)}>
              Close
            </Button>
          </Col>
        </Row>
      </Grid>
    );
  }
}

FindByDiverName.propTypes = {
  callback: PropTypes.func.isRequired,
};
export default FindByDiverName;
