module.exports.certificationStatus = {
  submitted: 'submitted',
  complete: 'complete',
  pending: 'pending'
}

module.exports.certificationBatchStatus = {
  new: 'new',
  downloaded: 'downloaded',
  printed: 'printed'
}

module.exports.leadership = {
  expired: 'expired',
  //fields not complete
  incomplete: 'incomplete',
  //pending review by HQ
  pending: 'pending',
  //complete, reviewed and not expired
  current: 'current'
}