import React, { Component, Fragment } from 'react';
import { Grid, Row, Col, Panel, Button, FormGroup, FormControl, ControlLabel, Checkbox } from 'react-bootstrap';
import ValidatedFormGroup from '../../ValidatedFormGroup.js';
import ValidatedDateFields from '../../Generic/ValidatedDateFields/ValidatedDateFields.js';
import GridListRadio from '../../lists/GridListRadio.js';
import { columns } from './gridColumns';
import { runRules, notAllValid } from '../../../libs/validation/validator.js';
import FindDiverUiModal from '../../FindDiverUiModal/FindDiverUiModal.js';
import CertificationRenewalUI from './CertificationRenewalUI.js';
import certificationOffering from '../../../model/CertificationOffering.js';
import certification from '../../../model/Certification.js';
import person from '../../../model/Person.js';
import moment from 'moment';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import _ from 'lodash';

class CertificationEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certification: {},
      selection: -1,
      processingMessage: 'Awaiting Data',
      certificationOfferings: [],
      ctl_editVisible: true,
      ctl_deleteVisible: false,
      ctl_error: false,
      ctl_errorMessage: '',
      ctl_findInstructor: false,
      ctl_renewVisible: false,
      expirationDay: '31',
      expirationMonth: '12',
      expirationYear: moment().year().toString(),
      validationErrors: {},
    };
  }

  componentDidMount = async () => {
    var certOfferings = await certificationOffering.getListOfOfferings();
    this.setState({ certificationOfferings: certOfferings });
  };

  getErrorFor = (field) => {
    return this.state.validationErrors[field] || '';
  };

  handleChange = (event) => {
    let newCertState = update(this.state.certification, {
      [event.target.id]: { $set: event.target.value },
    });
    this.setState({ certification: newCertState });
  };

  handleCbChange(attr, event) {
    let newCertState = update(this.state.certification, {
      [attr]: { $set: event.target.value },
    });
    this.setState({ certification: newCertState });
    console.log(this.state.certification);
  }

  handleCheckChange = (event) => {
    let newCertState = update(this.state.certification, {
      [event.target.id]: { $set: event.target.checked },
    });
    this.setState({ certification: newCertState });
  };

  handleDateChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  updateSelection = (selection) => {
    if (selection > -1) {
      var certification = { ...this.props.person.certifications[selection] };
      if (
        this.props.person.certifications[selection].expires &&
        this.props.person.certifications[selection].expires === true &&
        _.has(this.props.person.certifications[selection].expirationDate)
      ) {
        this.setState({
          selection: selection,
          day: moment(this.props.person.certifications[selection].certDate).format('DD'),
          month: moment(this.props.person.certifications[selection].certDate).format('MM'),
          year: moment(this.props.person.certifications[selection].certDate).format('YYYY'),
          expirationDay: moment(this.props.person.certifications[selection].expirationDate.format('DD')),
          expirationMonth: moment(this.props.person.certifications[selection].expirationDate.format('MM')),
          expirationYear: moment(this.props.person.certifications[selection].expirationDate.format('YYYY')),
          certification: certification,
        });
      } else {
        this.setState({
          selection: selection,
          day: moment(this.props.person.certifications[selection].certDate).format('DD'),
          month: moment(this.props.person.certifications[selection].certDate).format('MM'),
          year: moment(this.props.person.certifications[selection].certDate).format('YYYY'),
          certification: certification,
        });
      }
    } else {
      this.setState({ selection: selection });
    }
  };

  saveCertification = () => {
    var certificationData = this.state.certification;
    var certDate = moment(this.state.month + '/' + this.state.day + '/' + this.state.year, 'MM/DD/YYYY');
    var birthdate = moment(this.props.person.birthdate);
    certificationData.certDate = certDate;
    if (this.state.certification.expires === true) {
      var expirationDate = moment(
        this.state.expirationMonth + '/' + this.state.expirationDay + '/' + this.state.expirationYear,
        'MM/DD/YYYY'
      );
      certificationData.expirationDate = expirationDate;
    } else {
      delete certificationData.expirationDate;
    }
    var certInfo = _.find(this.state.certificationOfferings, { name: certificationData.certName });
    console.log(certInfo);
    certificationData.certLevel = certInfo.level;
    console.log(certificationData);
    //check if the cert date is valid. 12 years hence of birthdate
    if (certDate.isAfter(birthdate.add(12, 'years'))) {
      this.setState({ ctl_error: false });
      this.props.saveCertification(certificationData);
    } else {
      this.setState({
        ctl_error: true,
        ctl_errorMessage: 'Diver must be at least 12 years old to be certified',
      });
    }
  };

  selectCertList = async (event) => {
    var certOfferings;
    var selectedValue = event.target.value;
    console.log(selectedValue);
    switch (selectedValue) {
      case 'active':
        certOfferings = await certificationOffering.getListOfOfferings();
        this.setState({ ctl_selectedCertList: selectedValue, certificationOfferings: certOfferings });
        break;

      case 'all':
        certOfferings = await certificationOffering.getAllOfferings();
        this.setState({ ctl_selectedCertList: selectedValue, certificationOfferings: certOfferings });
        break;

      default:
        certOfferings = await certificationOffering.getListOfOfferings();
        this.setState({ ctl_selectedCertList: 'active', certificationOfferings: certOfferings });
        break;
    }
  };

  changeInstructorCallback = (visible, person = null) => {
    console.log('starting certification');
    console.log(this.state.certification);
    if (person !== null) {
      var newCertState = update(this.state.certification, {
        instructorId: { $set: person.personId },
        instructorFirstName: { $set: person.givenName },
        instructorLastName: { $set: person.surname },
      });
      this.setState({
        certification: newCertState,
        ctl_findInstructor: false,
      });
    } else {
      this.setState({ ctl_findInstructor: false });
    }
    console.log('ending certification');
    console.log(newCertState);
  };

  renewCertification = async (newExpirationDate = null) => {
    //renew is a prototype function of the certification object, so we have to get one of those.
    //THis is not at all guarranteed to be a complete object as we are not fetching from the databse,
    //Just constructing from the eexisting required data from the person object to get the function.
    //The renew function fundamentally only relies on the certId value anyhow.
    console.log('renewing"');
    var renewal = new certification(this.state.certification);
    console.log(renewal);
    var res = await renewal.renew(newExpirationDate);
    console.log('renewal result');
    console.log(res);
    alert(res.msg);
    await this.props.reloadPerson();
  };

  reprintCertification = async () => {
    var reprint = new certification(this.state.certification);
    try {
      await reprint.reprint();
      alert('Certification Marked for Reprint');
    } catch (err) {
      alert(err);
    }
    await this.props.reloadPerson();
  };

  renewalUiCallback = (visible, newExpirationDate) => {
    console.log('within callback');
    console.log(visible);
    console.log(newExpirationDate);
    this.setState({ ctl_renewVisible: visible });
    if (newExpirationDate) {
      this.renewCertification(newExpirationDate);
    }
  };

  render() {
    var cbOptions = this.state.certificationOfferings.map((item, idx) => {
      return (
        <option key={idx} value={item.name}>
          {item.name}
        </option>
      );
    });
    return (
      <Fragment>
        <FindDiverUiModal callback={this.changeInstructorCallback} visible={this.state.ctl_findInstructor} />
        <CertificationRenewalUI callback={this.renewalUiCallback} visible={this.state.ctl_renewVisible} />
        <Grid fluid={true}>
          <Panel>
            <Panel.Heading>
              {'Certifications for ' + this.props.person.givenName + ' ' + this.props.person.surname}
            </Panel.Heading>
            <Panel.Body>
              <GridListRadio
                data={this.props.person.certifications.map((i, idx) => {
                  return { ...i, _id: idx };
                })}
                columns={columns}
                selection={this.state.selection}
                updateSelection={this.updateSelection}
                processingMessage={this.state.processingMessage}
              />
            </Panel.Body>
          </Panel>
          <Panel>
            <Panel.Heading>Certification Actions</Panel.Heading>
            <Panel.Body>
              <Button disabled={this.state.selection < 0} onClick={() => this.renewalUiCallback(true)}>
                Renew Selected Certification
              </Button>
              <Button disabled={this.state.selection < 0} onClick={() => this.reprintCertification()}>
                Reprint Certification
              </Button>
              {/* <Button disabled={this.state.selection < 0}>Delete Selected Certification</Button> */}
            </Panel.Body>
          </Panel>
          {this.state.ctl_editVisible === true && this.state.selection > -1 ? (
            <Fragment>
              {this.state.ctl_error ? (
                <Panel bsStyle="danger">
                  <Panel.Heading>{this.state.ctl_errorMessage}</Panel.Heading>
                </Panel>
              ) : null}
              <Panel>
                <Panel.Heading>Certification List</Panel.Heading>
                <Panel.Body>
                  <FormGroup controlId="selectedCertList">
                    <FormControl
                      componentClass="select"
                      value={this.state.ctl_selectedCertList}
                      onChange={this.selectCertList.bind(this)}
                    >
                      <option value="active">Active Certifications</option>
                      <option value="all">All Certifications</option>
                    </FormControl>
                  </FormGroup>
                </Panel.Body>
              </Panel>
              <Panel>
                <Panel.Heading>Certification Editor</Panel.Heading>
                <Panel.Body>
                  <Row>
                    <Col md={4}>
                      <ControlLabel>
                        {'Instructor: ' +
                          this.state.certification.instructorFirstName +
                          ' ' +
                          this.state.certification.instructorLastName}
                      </ControlLabel>
                      <Button type="button" onClick={() => this.setState({ ctl_findInstructor: true })}>
                        Change Certifying Instructor
                      </Button>
                    </Col>
                    <Col md={8}></Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup controlId="formControlsSelect">
                        <ControlLabel>Certification:</ControlLabel>
                        <FormControl
                          componentClass="select"
                          placeholder="select cert..."
                          value={this.state.certification.certName || {}}
                          onChange={this.handleCbChange.bind(this, 'certName')}
                        >
                          {cbOptions}
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup controlId="deliverySelect">
                        <ControlLabel>Return Card To:</ControlLabel>
                        <FormControl
                          componentClass="select"
                          value={this.state.certification.cardReturn || {}}
                          onChange={this.handleCbChange.bind(this, 'cardReturn')}
                        >
                          <option value="instructor">Return to instructor</option>
                          <option value="student">Return to Student (additional postage fee may apply)</option>
                        </FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                  <ControlLabel>Certification Date:</ControlLabel>
                  <ValidatedDateFields
                    id_month="month"
                    id_day="day"
                    id_year="year"
                    month={this.state.month}
                    day={this.state.day}
                    year={this.state.year}
                    errors={this.state.validationErrors}
                    handleChange={this.handleDateChange}
                  />
                  <Row>
                    <Col md={12}>
                      <FormGroup controlId="expires">
                        <ControlLabel>Expires?</ControlLabel>
                        <Checkbox
                          id="expires"
                          checked={this.state.certification.expires || false}
                          onChange={this.handleCheckChange}
                          disabled={this.state.certification.expires}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.certification.expires ? (
                    <ValidatedDateFields
                      id_day="expirationDay"
                      id_month="expirationMonth"
                      id_year="expirationYear"
                      month={this.state.expirationMonth}
                      day={this.state.expirationDay}
                      year={this.state.expirationYear}
                      errors={this.state.validationErrors}
                      handleChange={this.handleDateChange}
                    />
                  ) : null}
                </Panel.Body>
                <Panel.Footer>
                  <Button
                    type="button"
                    disabled={notAllValid(this.state.validationErrors)}
                    onClick={() => this.saveCertification()}
                  >
                    Save Certification
                  </Button>
                </Panel.Footer>
              </Panel>
            </Fragment>
          ) : null}
        </Grid>
      </Fragment>
    );
  }
}
CertificationEditor.propTypes = {
  person: PropTypes.object.isRequired,
  saveCertification: PropTypes.func.isRequired,
  reloadPerson: PropTypes.func.isRequired,
};
export default CertificationEditor;
