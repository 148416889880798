import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import EditPerson from './EditPerson'
import { globalSetPerson } from '../../redux/global/actions';

const mapStateToProps = (state) => {
    return {
        person: state.person
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPerson: (person) => {dispatch(globalSetPerson(person))}
    }
}

class EditMyPerson extends Component {
    constructor(props){
        super(props)
        this.state = {
            ctl_complete: false
        }
    }

    handleEditPerson = async (person) => {
        try {
            await person.save()
            this.props.setPerson(person)
            this.setState({ctl_complete: true})
        } catch (error) {
            alert('Save Failed \n' + error)
        }
        
    }

    render(){
        if(this.state.ctl_complete === true){
            return(<Redirect to='/' push={true} />)
        }

        return (
            <Fragment>
                {
                    this.props.person && this.props.person.personId
                    ?
                    <EditPerson person={this.props.person} savePerson={this.handleEditPerson} allowEmailChange={false} />
                    :
                    null
                }
            </Fragment>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditMyPerson))