import React, { Component, Fragment} from 'react'
import CreatePerson from './CreatePerson.js'
import { Redirect } from 'react-router-dom'


class PureCreatePerson extends Component {

  constructor(props){
    super(props)
    this.state = {
      done: false
    }
  }

  personDone = () => {
    this.setState({done: true})
  }

  render(){
    return(
      <Fragment>
        {
          this.state.done === false
          ?
          <CreatePerson savePersonCallback={this.personDone} />
          :
          <Redirect to='/' push={true} />
        }
      </Fragment>
    )
  }
}


export default PureCreatePerson