import React, { Component } from 'react'
import { Modal, Grid, Row, Col, ControlLabel, Button } from 'react-bootstrap'
import ValidatedDateFields from '../../Generic/ValidatedDateFields/ValidatedDateFields.js'
import ValidatedFormGroup from '../../ValidatedFormGroup.js'
import { runRules, notAllValid } from '../../../libs/validation/validator.js'
import { fieldValidations } from './validations.js'
import moment from 'moment'
import update from 'immutability-helper'
import PropTypes from 'prop-types'

class CertificationRenewalUI extends Component {
  constructor(props){
    super(props)
    this.state = {
      day: '31',
      month: '12',
      year: moment().year().toString(),
      validationErrors: {}
    }
    this.state.validationErrors = runRules(this.state, fieldValidations)
  }

  handleComplete = () => {
    var expirationDate = moment(this.state.month + '/' + this.state.day + '/' + this.state.year, 'MM/DD/YYYY').format()
    this.props.callback(false, expirationDate)
  }

  handleChange = (event) => {
    let newState = update(this.state, {
      [event.target.id]: {$set: event.target.value}
    })
    this.setState(newState)
  }

  render(){
    return (
      <Modal show={this.props.visible} onHide={() => this.props.callback(false)}>
        <Modal.Header>
          <Modal.Title>{'New Expiration Date'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid={true}>
            <Row>
              <Col md={12}>
                <ControlLabel>New Certification Expiration Date</ControlLabel>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ValidatedDateFields
                  id_month='month'
                  id_day='day'
                  id_year='year'
                  month={this.state.month}
                  day={this.state.day}
                  year={this.state.year}
                  errors={this.state.validationErrors}
                  handleChange={this.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button type='button' onClick={() => this.handleComplete()}>Renew</Button>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
      </Modal>
    )
  }
}


CertificationRenewalUI.propTypes = {
  callback: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}

export default CertificationRenewalUI