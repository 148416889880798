//data model for person objects
import { apiName } from '../config.js';
import { API } from 'aws-amplify';
import fileType from 'file-type';
const moment = require('moment');
const _ = require('lodash');

// //const schema = masterSchema.person
// const apiName = config.apiName

var Person = function (input) {
  //load input into the protected data object. sanatize to match schema.
  //this.data = this.sanatize(input)
  this.data = input;

  //if no certifications, initialize the certifications array
  if (!_.has(this.data, 'certifications')) {
    this.data.certifications = [];
  }
  if (!_.has(this.data, 'isInstructor')) {
    this.data.isInstructor = false;
  }
  if (!_.has(this.data, 'email')) {
    this.data.email = null;
  }
  //setup verificationData
  // if (!_.has(this.data, 'verificationSearch') && this.data.givenName && this.data.surname && this.data.birthdate) {
  if (this.data.givenName && this.data.surname && this.data.birthdate) {
    this.data.verificationSearch = Person.buildVerificationSearchData(
      this.data.givenName,
      this.data.surname,
      this.data.birthdate
    );
  }
  //setup fullname search
  if (!_.has(this.data, 'fullName') && _.has(this.data, 'givenName') && _.has(this.data, 'surname')) {
    this.data.fullName = buildFullNameSearch(this.data.givenName, this.data.surname);
  }
  //define properties
  Object.defineProperty(this, 'personId', {
    value: this.data.personId,
    writable: false,
    configurable: false,
    enumerable: true,
  });
  Object.defineProperty(this, 'cognitoUsername', {
    get: function () {
      return this.data.cognitoUsername;
    },
    set: function (value) {
      this.data.cognitoUsername = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'identityId', {
    get: function () {
      return this.data.identityId;
    },
    set: function (value) {
      this.data.identityId = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'givenName', {
    get: function () {
      return this.data.givenName;
    },
    set: function (value) {
      this.data.givenName = value;
      this.data.verificationSearch = Person.buildVerificationSearchData(
        this.data.givenName,
        this.data.surname,
        this.data.birthdate
      );
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'middleName', {
    get: function () {
      return this.data.middleName;
    },
    set: function (value) {
      this.data.middleName = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'surname', {
    get: function () {
      return this.data.surname;
    },
    set: function (value) {
      this.data.surname = value;
      this.data.verificationSearch = Person.buildVerificationSearchData(
        this.data.givenName,
        this.data.surname,
        this.data.birthdate
      );
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'address1', {
    get: function () {
      return this.data.address1;
    },
    set: function (value) {
      this.data.address1 = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'address2', {
    get: function () {
      return this.data.address2;
    },
    set: function (value) {
      this.data.address2 = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'city', {
    get: function () {
      return this.data.city;
    },
    set: function (value) {
      this.data.city = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'state', {
    get: function () {
      return this.data.state;
    },
    set: function (value) {
      this.data.state = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'postalCode', {
    get: function () {
      return this.data.postalCode;
    },
    set: function (value) {
      this.data.postalCode = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'country', {
    get: function () {
      return this.data.country;
    },
    set: function (value) {
      this.data.country = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'phone', {
    get: function () {
      return this.data.phone;
    },
    set: function (value) {
      this.data.phone = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'email', {
    get: function () {
      return this.data.email;
    },
    set: function (value) {
      this.data.email = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'birthdate', {
    get: function () {
      return this.data.birthdate.substring(0, this.data.birthdate.indexOf('T'));
    },
    set: function (value) {
      console.log(`in setter ${value}`);
      this.data.birthdate = value;
      this.data.verificationSearch = Person.buildVerificationSearchData(
        this.data.givenName,
        this.data.surname,
        this.data.birthdate
      );
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'diverIdNumber', {
    get: function () {
      return this.data.diverIdNumber;
    },
    set: function (value) {
      this.data.diverIdNumber = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'isInstructor', {
    get: function () {
      return this.data.isInstructor;
    },
    set: function (value) {
      this.data.isInstructor = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'leadership', {
    get: function () {
      return this.data.leadership;
    },
    set: function (value) {
      this.data.leadership = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'certifications', {
    get: function () {
      return this.data.certifications;
    },
    set: function (value) {
      this.data.certifications = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'verificationSearch', {
    get: function () {
      return this.data.verificationSearch;
    },
    set: function (value) {
      this.data.verificationSearch = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'fullName', {
    get: function () {
      return this.data.fullName;
    },
    set: function (value) {
      this.data.fullName = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'cardPicURL', {
    get: function () {
      return this.data.cardPicURL;
    },
    set: function (value) {
      this.data.cardPicURL = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'defaultRegion', {
    get: function () {
      return this.data.defaultRegion;
    },
    set: function (value) {
      this.data.defaultRegion = value;
    },
    enumerable: true,
  });
  Object.defineProperty(this, 'createdOn', {
    value: this.data.createdOn,
    writable: false,
    enumerable: true,
  });

  Object.defineProperty(this, 'lastModified', {
    value: this.data.lastModified,
    writable: false,
    enumerable: true,
  });

  //Make the data object private
  Object.defineProperty(this, 'data', {
    enumerable: true,
  });
};
//Object Functions
Person.prototype.certify = async function (certification, cardReturn) {
  console.log(certification);
  console.log(cardReturn);

  var path = '/person/certify';
  var myInit = {
    body: {
      certification: certification.data,
      cardReturn: cardReturn,
      certifiedPersonId: this.data.personId,
    },
  };

  try {
    var response = await API.post(apiName, path, myInit);
    console.log(response);
    this.data.certifications.push(response);
    this.save();
  } catch (e) {
    console.log(e);
    alert(e);
  }
};

// Person.prototype.changeEmail = async function(email, authState, authData = {}){
//   //I am logged in as this person, but not connected
//   if(authState === 'signedIn' && !_.has(this.data, 'identityId') && !_.has(this.data, 'cognitoUsername') && authData.attributes.email && !_.has(authData.attributes, 'profile')){
//     this.data.email = authData.attributes.email
//     return(await this.save())
//   }
//   //TODO: Deal with connected user email change
//
// }

Person.prototype.addCognitoIdentity = async function () {
  var path = '/person/addIdentity';
  var myInit = {
    body: { personId: this.data.personId },
  };
  try {
    var response = await API.post(apiName, path, myInit);
    return new Person(response);
  } catch (e) {
    console.log(e);
    alert(e);
  }
};

Person.prototype.save = async function () {
  var data = this.data;
  var path = '/person';
  var myInit = {
    body: { data },
  };
  var response = await API.post(apiName, path, myInit);
  return new Person(response);
};

Person.prototype.saveImage = async function (imageString) {
  //required base64 encoded string of the image
  var path = '/person/' + this.data.personId + '/photo';
  var myInit = {
    body: imageString,
  };

  var response = await API.post(apiName, path, myInit);
  return response;
};

Person.prototype.getImage = async function () {
  var path = '/person/' + this.data.personId + '/photo';

  var response = await API.get(apiName, path);
  if (response.message && response.message === 'The specified key does not exist.') {
    return null;
  } else {
    var resBuffer = Buffer.from(response.Body);
    var fileData = fileType(resBuffer);
    var base64encoded = resBuffer.toString('base64');
    var dataURL = 'data:' + fileData.mime + ';base64,' + base64encoded;

    return dataURL;
  }
};

//Static functions
Person.findById = async function (personId) {
  return await findPersonById('/person/' + personId);
};

Person.findByIdentityId = async function (identityId) {
  return findPersonById('/person');
};

Person.findByDiverId = async function (diverId) {
  return findPersonById('/person/diver/' + diverId);
};

Person.listAllPeople = async function () {
  var path = '/admin/person/list';
  var response = await API.get(apiName, path);
  if (response.err) {
    throw response.err;
  }
  console.log(response);
};

Person.findByName = async function (givenName, surname) {
  var firstName = givenName.replace(/[^A-Z0-9]+/gi, '');
  var lastName = surname.replace(/[^A-Z0-9]+/gi, '');

  var path = '/person/findByName/' + firstName + '/' + lastName;

  try {
    var response = await API.get(apiName, path);
    if (response.err) {
      throw response.err;
    }
    if (response === 'no data') {
      return 'no Records Found';
    }
    var people = [];
    for (var i = 0; i < response.length; i++) {
      people.push(new Person(response[i]));
    }
    return people;
  } catch (e) {
    alert(e);
  }
};

Person.verifyDiver = async function (firstName = null, lastName = null, birthdate = null, diverIdNumber = null) {
  var path = '/verify';
  let myInit = {
    body: {
      firstName: firstName,
      lastName: lastName,
      birthdate: birthdate,
      diverIdNumber: diverIdNumber,
    },
  };

  try {
    var response = await API.post(apiName, path, myInit);
    return response;
  } catch (e) {
    alert(e);
  }
};

const findPersonById = async function (path) {
  try {
    var response = await API.get(apiName, path);
    if (response.err) {
      throw response.err;
    }
    var newPerson = new Person(response);
    return newPerson;
  } catch (e) {
    alert(e);
  }
};

const buildFullNameSearch = function (givenName, surname) {
  var firstName = givenName.replace(/[^A-Z0-9]+/gi, '');
  var lastName = surname.replace(/[^A-Z0-9]+/gi, '');
  var fullName = lastName.toLowerCase() + '-' + firstName.toLowerCase();
  return fullName;
};

Person.buildVerificationSearchData = function (givenName, surname, birthdate) {
  console.log('building');
  var day = moment(birthdate).format('DD');
  var month = moment(birthdate).format('MM');
  var year = moment(birthdate).format('Y');
  var firstName = givenName.replace(/[^A-Z0-9]+/gi, '');
  var lastName = surname.replace(/[^A-Z0-9]+/gi, '');

  var verificationData = year + '-' + month + '-' + day + '-' + lastName.toLowerCase() + '-' + firstName.toLowerCase();

  return verificationData;
};

export default Person;
