import moment from 'moment';

export const columns = [
  {
    Header: 'Instructor Name',
    id: 'instructorFullName',
    accessor: (d) => d.instructorLastName + ', ' + d.instructorFirstName,
  },
  {
    Header: 'Certification',
    id: 'diverCertificationName',
    accessor: (d) => d.certName,
  },
  {
    Header: 'Certification Date',
    id: 'certificationDateFormatted',
    accessor: (d) => moment(d.certDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
  },
  {
    Header: 'Status',
    id: 'certificationStatus',
    accessor: (d) => d.status,
  },
  {
    Header: 'Expiration Date',
    id: 'expirationDate',
    accessor: (d) => {
      if (d.expirationDate) {
        return moment(d.expirationDate).format('MM/DD/YYYY');
      } else {
        return null;
      }
    },
  },
];
