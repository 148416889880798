import React, { Component, Fragment } from 'react'
import {Grid, Row, Col, Panel, ControlLabel, FormGroup, FormControl, Button } from 'react-bootstrap'
import ValidatedDateFields from '../Generic/ValidatedDateFields/ValidatedDateFields.js'
import { fieldValidations } from './validations.js'
import { runRules, notAllValid } from '../../libs/validation/validator.js'
import update from 'immutability-helper'
import moment from 'moment'
import PropTypes from 'prop-types'


class CertifyUi extends Component {
  constructor(props){
    super(props)
    this.state = {
      month: '',
      day: '',
      year: '',
      cardReturn: 'instructor',
      cert_name: 'OPEN WATER DIVER',
      ctl_error: false,
      ctl_errorMessage: '',
      validationErrors: {}
    }

    this.state.validationErrors = runRules(this.state, fieldValidations)

    this.handleChange = this.handleChange.bind(this)
    this.handleComplete = this.handleComplete.bind(this)
  }

  getErrorFor(field){
    return this.state.validationErrors[field] || "";
  }

  handleComplete = async () => {
    var certDate = moment(this.state.month + '/' + this.state.day + '/' + this.state.year, 'MM/DD/YYYY')
    var birthdate = moment(this.props.person.birthdate)
    //check if person is 12 years old at date of certification
    if(certDate.isAfter(birthdate.add(12, 'years'))){
      this.setState({ctl_error: false})
      this.props.callback(certDate, this.state.cert_name, this.state.cardReturn)
    } else {
      this.setState({
        ctl_error: true,
        ctl_errorMessage: "Diver must be at least 12 years old to be certified"
      })
    }

  }

  handleChange = (event) => {
    let newState = update(this.state, {
      [event.target.id]: {$set: event.target.value}
    });
    newState.validationErrors = runRules(newState, fieldValidations);
    console.log(newState);
    this.setState(newState);
  }

  handleCbChange(attr, event){
    this.setState({
      [attr]: event.target.value
    })
  }

  render(){
    var cbOptions = this.props.allowedCertifications.map((item, idx) => {
      return (
        <option key={idx} value={item.name}>{item.name}</option>
      )
    })
    return (
      <Fragment>
        {
          this.state.ctl_error
          ?
          <Panel bsStyle='danger'><Panel.Heading>{this.state.ctl_errorMessage}</Panel.Heading></Panel>
          :
          null
        }
        <Panel>
          <Panel.Heading>
            <Panel.Title><h3>Certification Information</h3></Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Grid>
              <Row>
                <Col md={6}>
                  <FormGroup controlId='formControlsSelect'>
                    <ControlLabel>Certification</ControlLabel>
                    <FormControl componentClass="select" placeholder='select cert...' value={this.state.cert_name || {}} onChange={this.handleCbChange.bind(this, "cert_name")}>
                      {cbOptions}
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup controlId='deliverySelect'>
                    <ControlLabel>Return Card To:</ControlLabel>
                    <FormControl componentClass='select' value={this.state.cardReturn || {}} onChange={this.handleCbChange.bind(this, "cardReturn")}>
                      <option value='instructor'>Return to instructor</option>
                      <option value='student'>Return to Student (additional postal fee will apply)</option>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ControlLabel>Certification Date (MM/DD/YYYY)</ControlLabel>
                </Col>
              </Row>
              <ValidatedDateFields
                id_day='day'
                id_month='month'
                id_year='year'
                month={this.state.month}
                day={this.state.day}
                year={this.state.year}
                errors={this.state.validationErrors}
                handleChange={this.handleChange}
              />
              <Row>
                <Col md={4}>
                  <Button type='button' onClick={() => this.handleComplete()} disabled={notAllValid(this.state.validationErrors)}>Submit</Button>
                </Col>
              </Row>
            </Grid>
          </Panel.Body>
        </Panel>
      </Fragment>
    )
  }
}

CertifyUi.propTypes = {
  callback: PropTypes.func.isRequired,
  person: PropTypes.object.isRequired,
  allowedCertifications: PropTypes.array.isRequired
}

export default CertifyUi