export const columns = [
  {
    Header: 'Diver Name',
    id: 'fiverFullName',
    accessor: d => d.surname + ", " + d.givenName
  },
  {
    Header: 'Diver ID Number',
    id: 'fiverIdNumber',
    accessor: d => d.diverIdNumber
  }
]