export const isRequired = fieldName => `${fieldName} is required`;

export const mustMatch = matchFieldName => {
  return (fieldName) => `${fieldName} must match ${matchFieldName}`;
};

export const minLength = length => {
  return (fieldName) => `${fieldName} must be at least ${length} characters`;
};

export const mustBeNumeric = fieldName => `${fieldName} must be numeric.`;

export const invalidYear = fieldName => 'Not a valid year'
