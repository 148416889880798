import React, { Component } from 'react';
import { Button, Grid, Row, Col, ControlLabel } from 'react-bootstrap';
import GridListRadio from '../../lists/GridListRadio.js';
import { certificationBatchStatus } from '../../../model/statusCodes.js';
import { columns } from './gridColumns.js';
import certificationBatch from '../../../model/CertificationBatch.js';
import { CSVLink } from '../../Generic/CSVDownload/index.js';
import { CbCertificationStatusOptions } from '../../../libs/uiListBuilders.js';
import { certBatchData, headers } from '../../../model/presentation/certificationBatchDataView.js';
import printFileExporter from '../../../model/presentation/certificationBatchExternalPrintView.js';
import customCardExporter from '../../../model/presentation/certificationBatchCrmCardView'
import uuid from 'uuid';
import _, { head } from 'lodash';

class CertificationBatchProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      selection: -1,
      processingMessage: 'Awaiting Data',
      ctl_showAll: false,
      ctl_downloadVisible: false,
      csvData: {},
      prepState: 'notReady',
      headers: []
    };
  }

  componentDidMount = async () => {
    await this.loadData();
  };

  loadData = async () => {
    console.log('===data load cert batch processing===');
    this.setState({ processingMessage: 'loading...', selection: -1, prepState: 'notReady' });
    var batchList = [];
    //2 possible status codes we care about
    var batchListNew = await certificationBatch.listByStatus('new');
    var batchListDl = await certificationBatch.listByStatus('downloaded');
    //It is possible to not have one or the other
    if (batchListNew && batchListNew.length > 0) {
      for (var i = 0; i < batchListNew.length; i++) {
        batchList.push(batchListNew[i]);
      }
    }
    if (batchListDl && batchListDl.length > 0) {
      for (var i = 0; i < batchListDl.length; i++) {
        batchList.push(batchListDl[i]);
      }
    }
    //after all of the above, check to see if we actually got something.
    if (batchList && batchList.length && batchList.length > 0) {
      //tack on the _id field required by the GridList Component
      var data = batchList.map((i, idx) => {
        return { ...i, _id: idx };
      });
      //once mapped add to state
      this.setState({
        data: data,
        processingMessage: 'Awaiting Data',
      });
    } else {
      this.setState({ processingMessage: 'No Data' });
    }
  };

  completeBatch = async () => {
    console.log('===complete data===');
    var batch = new certificationBatch(this.getSelectedBatch().data);
    // if(batch.status === certificationBatchStatus.downloaded){
    batch.status = certificationBatchStatus.printed;
    await batch.save();
    await this.loadData();
    // } else {
    //   alert('Batch has not been downloaded')
    // }
  };

  updateSelection = (selection) => {
    this.setState({ selection: selection, prepState: 'notReady', csvData: {} });
  };

  getSelectedBatch = (id = this.state.selection) => {
    //gets the certification batch object for the currently selected batch in the grid
    var index = _.findIndex(this.state.data, (i) => {
      return i._id === id;
    });
    return this.state.data[index];
  };

  prepareBatchForDownload = async () => {
    this.setState({ prepState: 'preparing' });
    var batch = new certificationBatch(this.getSelectedBatch().data);
    console.log(batch);
    if (batch && batch.batchId) {
      var csvData = await certBatchData(batch);
      this.setState({ csvData: csvData, prepState: 'ready', headers: headers });
    }
  };

  preparePrintBatchForDownload = async () => {
    this.setState({ prepState: 'preparing' });
    var batch = new certificationBatch(this.getSelectedBatch().data);
    console.log(batch);
    if (batch && batch.batchId) {
      var csvData = await printFileExporter.certBatchPrintFileExporter(batch);
      this.setState({ csvData: csvData, prepState: 'ready', headers: printFileExporter.headers });
    }
  };

  prepareBatchForCustomCardDb = async () => {
    this.setState({ prepState: 'preparing' });
    var batch = new certificationBatch(this.getSelectedBatch().data);
    console.log(batch);
    if (batch && batch.batchId) {
      var csvData = await customCardExporter.certBatchPrintFileExporter(batch);
      this.setState({ csvData: csvData, prepState: 'ready', headers: customCardExporter.headers });
    }
  }

  batchDownloadCallback = async (id, data) => {
    console.log('===batch callback===');
    // var batch = new certificationBatch(this.getSelectedBatch(id).data);
    // batch.status = certificationBatchStatus.downloaded;
    // console.log(batch);
    // await batch.save();
    // await this.loadData();
  };

  render() {
    console.log('====render function====');
    // console.log(this.state.csvData);
    return (
      <Grid fluid={true}>
        <Row>
          <Col md={12}>
            <h2>Certification Batches</h2>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <ControlLabel>
              1. Use Card Manager to download the batches into the card printing system. Click prepare batch below to
              setup a download link for the batch data
            </ControlLabel>
            {(() => {
              switch (this.state.prepState) {
                case 'notReady':
                  return (
                    <div>
                    <Button type="button" onClick={() => this.prepareBatchForDownload()}>
                      Prepare Batch
                    </Button>
                    <Button type="button" onClick={() => this.preparePrintBatchForDownload()}>
                      Prepare German Print File
                    </Button>
                    <Button type="button" onClick={() => this.prepareBatchForCustomCardDb()}>
                      Prepare Custom Card File
                    </Button>
                    </div>
                  );
                case 'preparing':
                  return <ControlLabel>Preparing...</ControlLabel>;
                case 'ready':
                  return (
                    <CSVLink
                      data={this.state.csvData}
                      filename={this.getSelectedBatch().batchName + '.csv'}
                      headers={this.state.headers}
                      callback={this.batchDownloadCallback}
                      linkId={this.state.data[0].batchId}
                    >
                      Download Batch
                    </CSVLink>
                  );
              }
            })()}
            <ControlLabel>2. After You have Printed and Mailed the cards in the selected Batch:</ControlLabel>
            <Button type="button" onClick={() => this.completeBatch()}>
              Complete Batch
            </Button>
          </Col>
          <Col md={10}>
            <GridListRadio
              data={this.state.data}
              columns={columns}
              selection={this.state.selection}
              updateSelection={this.updateSelection}
              processingMessage={this.state.processingMessage}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}
export default CertificationBatchProcessing;
