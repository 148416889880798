import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import GridListReadOnly from '../lists/GridListReadOnly.js'
import { columns } from './gridColumns.js'
import certification from '../../model/Certification.js'
import { listAddDiver } from '../../model/presentation/certificationViews.js'

class InstructorCertStatusList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      processingMessage: 'Awaiting Data'
    }
  }

  componentDidMount = async () => {
    await this.loadData()
  }

  loadData = async () => {
    this.setState({ processingMessage: 'loading...' })
    var data = []
    var view = []
    data = await certification.listCertsForThisInstructor();
    if (data === 'No Records Found') {
      this.setState({ processingMessage: 'No Data' })
      return
    } else {
      view = await listAddDiver(data)
    }

    if (view && view.length && view.length > 0) {
      this.setState({
        data: view,
        processingMessage: 'Awaiting Data'
      })
    } else {
      this.setState({ processingMessage: 'No Data' })
    }
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <GridListReadOnly
              data={this.state.data}
              columns={columns}
              processingMessage={this.state.processingMessage}
            />
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default InstructorCertStatusList