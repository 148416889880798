import React from'react'
import { Grid, Row, Col, Button, Panel, ControlLabel } from 'react-bootstrap'
import PropTypes from 'prop-types'

const AcceptanceEditor = (props) => {

    return(
        <Panel>
            <Panel.Heading>
                <ControlLabel>Accept Agreements</ControlLabel>
            </Panel.Heading>
            <Panel.Body>
                <Grid fluid={true}>
                    <Row>
                        <Col md={12}>
                            <p>
                                Receipt of this renewal application does not constitute completion of re-certification and does not constitute completion of a contract on the part of SEI. All applications for renewal and re-certification are subject to evaluation, approval and acceptance by SEI. It is your responsibility to ensure all prerequisites, completed forms and fees are delivered to SEI headquarters by December 31. SEI reserves the right to non-renewal or decline services to any person.
                            </p>
                            <p>By clicking "I Agree", you are agreeing to all of the terms and conditions outlined above</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button type='button' onClick={() => props.acceptAgreement()}>I agree</Button>
                        </Col>
                    </Row>
                </Grid>
            </Panel.Body>
        </Panel>
    )
}

AcceptanceEditor.propTypes = {
    acceptAgreement: PropTypes.func.isRequired
}
export default AcceptanceEditor