import { API } from 'aws-amplify'
import { apiName } from '../config.js'
import _ from 'lodash'

var CertificationOffering = function(input){

  this.data = input

  Object.defineProperty(this, 'name', {
    get: function(){
      return this.data.name
    },
    set: function(value) {
      this.data.name = value
    },
    enumerable: true
  })

  Object.defineProperty(this, 'level', {
    get: function(){
      return this.data.level
    },
    set: function(value) {
      this.data.level = value
    },
    enumerable: true
  })

  Object.defineProperty(this, 'active', {
    get: function(){
      return this.data.active
    },
    set: function(value) {
      this.data.active = value
    },
    enumerable: true
  })
}

CertificationOffering.getListOfOfferings = async function(){
  //returns only active certifications
  var path = '/certification/courseofferings'
  try {
    var response = await API.get(apiName, path)

    var filteredOfferings = _.remove(response, (o) => {return o.active == 'true'})

    var sortedOfferings = _.sortBy(filteredOfferings, [function (o) {return o.name}])
    
    return sortedOfferings
  } catch (e) {
    alert(e)
  }
}

CertificationOffering.getAllOfferings = async function(){
  //returns all offerings, active and inactive. Used by the admin enter cert page if selected
  var path = '/certification/courseofferings'
  try{
    var response = await API.get(apiName, path)

    var sortedOfferings = _.sortBy(response, [function (o) {return o.name}])

    return sortedOfferings
  } catch (e) {
    alert(e)
  }
}
export default CertificationOffering
