import React, { Component } from 'react';
import { Button, Grid, Row, Col, ControlLabel, FormGroup, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import certification from '../../../model/Certification.js';
import GridListChecked from '../../lists/GridListChecked.js';
import { runRules, notAllValid } from '../../../libs/validation/validator.js';
import { fieldValidations } from './validations.js';
import ValidatedFormGroup from '../../ValidatedFormGroup.js';
import { certificationStatus, certificationBatchStatus } from '../../../model/statusCodes.js';
import { columns } from './gridColumns.js';
import { listAddDiver } from '../../../model/presentation/certificationViews.js';
import certificationBatch from '../../../model/CertificationBatch.js';
import update from 'immutability-helper';
import uuid from 'uuid';
import _ from 'lodash';

const mapStateToProps = (state) => {
  return {
    authState: state.authState,
    authData: state.authData,
    person: state.person,
  };
};

class CertificationProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // sel_instructorId: '11602d54-81d0-473a-b671-cbf307e433d9',
      sel_status: 'submitted',
      data: [],
      selection: [],
      processingMessage: 'Awaiting Data',
      validationErrors: {},
      creatingBatch: false,
    };

    this.state.validationErrors = runRules(this.state, fieldValidations);
  }

  componentDidMount = () => {
    this.loadData(this.state.sel_status);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      console.log('updating');
      this.loadData(this.state.sel_status);
    }
  };

  loadData = async (status) => {
    this.setState({ processingMessage: 'loading...', data: [] });
    var data = [];
    var certs = [];
    data = await certification.listCertsByStatus(status);
    if (data === 'No Records Found') {
      this.setState({ processingMessage: 'No Data' });
      return;
    } else {
      certs = await listAddDiver(data);
    }

    if (certs.length > 0) {
      this.setState({
        data: certs,
        processingMessage: 'Awaiting Data',
      });
    } else {
      this.setState({ processingMessage: 'No Data' });
      return;
    }
  };

  createBatch = async () => {
    this.setState({ creatingBatch: true });
    try {
      var batchData = {
        batchId: uuid(),
        creatorUsername: this.props.authData.username,
        status: certificationBatchStatus.new,
        batchName: this.state.batchName,
        certifications: [],
      };
      for (var i = 0; i < this.state.selection.length; i++) {
        var selectedIndex = _.findIndex(this.state.data, (item) => {
          return item._id === this.state.selection[i];
        });
        batchData.certifications.push(this.state.data[selectedIndex].certification.certId);
        var cert = await certification.findById(this.state.data[selectedIndex].certification.certId);
        cert.status = certificationStatus.pending;
        await cert.save();
      }
      var batch = new certificationBatch(batchData);
      batch.save();
      alert('Batch ' + this.state.batchName + ' Saved');
      this.setState({ creatingBatch: false });
      await this.loadData(this.state.sel_status);
    } catch (e) {
      alert(e);
      this.setState({ creatingBatch: false });
    }
  };

  updateSelection = (selection) => {
    this.setState({ selection: selection });
  };

  getErrorFor(field) {
    return this.state.validationErrors[field] || '';
  }

  handleChange = (event) => {
    let newState = update(this.state, {
      [event.target.id]: { $set: event.target.value },
    });
    newState.validationErrors = runRules(newState, fieldValidations);
    this.setState(newState);
  };

  handleCbChange = async (attr, event) => {
    this.setState({
      [attr]: event.target.value,
    });
    console.log(this.state);
    if (attr === 'sel_status') {
      console.log('loadiong data');
      await this.loadData(event.target.value);
    }
  };

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <h2>New Certifications</h2>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <FormGroup controlId="sel_status">
              <ControlLabel>Certification status</ControlLabel>
              <FormControl
                componentClass="select"
                value={this.state.sel_status}
                onChange={this.handleCbChange.bind(this, 'sel_status')}
              >
                <option value="submitted">Submitted</option>
                <option value="reprint">Reprint</option>
              </FormControl>
            </FormGroup>
            <ValidatedFormGroup
              id="batchName"
              label="Batch Name"
              showError={true}
              errorText={this.getErrorFor('batchName')}
              type="test"
              autoFocus
              value={this.state.batchName || ''}
              onChange={this.handleChange}
            />
            {this.state.creatingBatch ? (
              <ControlLabel>Creating Batch...</ControlLabel>
            ) : (
              <Button
                type="button"
                onClick={() => this.createBatch()}
                disabled={this.state.selection.length < 1 || notAllValid(this.state.validationErrors)}
              >
                Create Batch
              </Button>
            )}
          </Col>
          <Col md={10}>
            <GridListChecked
              data={this.state.data}
              columns={columns}
              selection={this.state.selection}
              updateSelection={this.updateSelection}
              processingMessage={this.state.processingMessage}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}
export default connect(mapStateToProps)(CertificationProcessing);
