// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cloud_logic': 'enable',
    'aws_cloud_logic_custom': [{"id":"vji73mr5j1","name":"prod-diverportal-api","description":"","endpoint":"https://vji73mr5j1.execute-api.us-east-1.amazonaws.com/prod","region":"us-east-1","paths":["/admin/certifications/batch","/admin/certifications/batch/list/{status}","/admin/certifications/batch/{id}","/admin/certifications/list/status/{status}","/admin/certifications/list/{id}/{status}","/admin/instructor/certifications/list/{id}","/admin/person/list","/certification","/certification/courseofferings","/certification/{id}","/instructor/certifications/list","/leadership","/leadership/{id}","/person","/person/addIdentity","/person/certify","/person/diver/{id}","/person/findByName/{givenName}/{surname}","/person/leadership/{id}","/person/{id}","/verify"]}],
    'aws_cognito_identity_pool_id': 'us-east-1:9cbab3be-38d5-4947-b5d8-e4c66f1fced6',
    'aws_cognito_region': 'us-east-1',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'diverportalwebapp-hosting-mobilehub-1333625667',
    'aws_content_delivery_bucket_region': 'us-east-1',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'd218ac067gaj8f.cloudfront.net',
    'aws_mobile_analytics_app_id': '43977c2363e440a08f34de47bc1a98ec',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': '5d63bd0e-6aa5-450e-b5be-d41ccfb4b6c1',
    'aws_project_name': 'diverportal-webapp',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'diverportalwebapp-mobilehub-1333625667',
    'aws_sign_in_enabled': 'enable',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'diverportalwebapp-userfiles-mobilehub-1333625667',
    'aws_user_files_s3_bucket_region': 'us-east-1',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_PEWeRVHaA',
    'aws_user_pools_mfa_type': 'ON',
    'aws_user_pools_web_client_id': '1a7ig9t262stmnh05jk22p38i1',
}

export default awsmobile;
