import moment from 'moment'

export const columns = [
  {
    Header: 'Batch Name',
    id: 'batchName',
    accessor: d => d.batchName
  },
  {
    Header: 'Number of Certifications',
    id: 'certCount',
    accessor: d => d.certifications.length
  },
  {
    Header: 'Created By',
    id: 'creatorUsername',
    accessor: d => d.creatorUsername
  },
  {
    Header: 'Created On',
    id: 'createdOn',
    accessor: d => moment(d.createdOn).format("MM/DD/YYYY")
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: d => d.status
  }
]