//validation rules from FindDiverPanel

import { validator } from '../../libs/validation/validator.js';
import { required, isNumeric } from '../../libs/validation/rules.js';

export const fieldValidations = [
 validator("diverIdNumber", "Diver Id Number", required, isNumeric),
]

export const nameValidations = [
  validator('givenName', 'First Name', required),
  validator('surname', 'Last Name', required)
]