import React, {Component } from 'react'
import { Storage } from 'aws-amplify'
import person from '../../model/Person.js'
import Avatar from 'react-avatar'
import PropTypes from 'prop-types'

const customPrefix = {
  public: 'diverPhotos/'
}

class PersonPhotoViewer extends Component {

  _isMounted = false

  constructor(props){
    super(props)
    this.state = {
      img: ''
    }
  }

  componentDidMount = () => {
    this._isMounted = true
    this.getPic(this.props.personId)
  }

  componentDidUpdate = (prevProps) => {
    console.log('update');
    console.log(prevProps);
    if(prevProps.personId !== this.props.personId){
      this.getPic(this.props.personId)
    }
  }

  getPic = async (personId) => {
    var workingPerson = await person.findById(personId)
    var res = await workingPerson.getImage()
    if(this._isMounted === true){
      this.setState({img: res})
    }
  }

  render(){
    return(
      <Avatar src={this.state.img} />
    )
  }
}

PersonPhotoViewer.propTypes = {
  personId: PropTypes.string.isRequired
}

export default PersonPhotoViewer