import React, { Component, Fragment } from 'react'
import { Jumbotron } from 'react-bootstrap'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css'

const CheckboxTable = checkboxHOC(ReactTable)

class GridListChecked extends Component {
  constructor(props){
    super(props)
    this.state = {
      selectAll: false
    }
  }

  toggleSelection = (key, shift, row) => {
    //current selection loaded formstate
    let selection = [...this.props.selection]
    //check if key already exists in selection
    const keyIndex = selection.indexOf(key)
    if(keyIndex >= 0){
      //does exist so we will remove it
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ]
    } else {
      //it does not exist in selection, so add it
      selection.push(key)
    }
    //finally store new selection to state
    this.props.updateSelection(selection)
    // this.setState({ selection })
  }

  isSelected = key => {
    return this.props.selection.includes(key);
  };

  toggleAll = () => {
    //toggles sel;ection of all data after ReactTable filtering
    const selectAll = this.state.selectAll ? false : true
    const selection = []
    if(selectAll) {
      //get the internal wrapped ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance()
      //get the sorted data from the wrapped instance. This is what is presently visible in the editor
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      //push them all into the selection array
      currentRecords.forEach(item => {
        selection.push(item._original._id)
      })
    }
    this.setState({ selectAll })
    this.props.updateSelection(selection)
    // this.setState({ selectAll, selection})
  }

  render() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;
    const { data, columns } = this.props

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: "checkbox",
      // getTrProps: (s, r) => {
      //   // someone asked for an example of a background color change
      //   // here it is...
      //   const selected = this.isSelected(r.original._id)
      //   return {
      //     style: {
      //       backgroundColor: selected ? "lightgreen" : "inherit"
      //       // color: selected ? 'white' : 'inherit',
      //     }
      //   }
      // }
    }

    return (
      <Fragment>
        {
          data.length || data.length > 0
          ?
          <CheckboxTable
            ref={r => (this.checkboxTable = r)}
            data={data}
            columns={columns}
            defaultPageSize={10}
            className='-striped -highlight'
            filterable
            sortable
            {...checkboxProps}
          />
          :
          <Fragment>
            <Jumbotron>
              <h2>{this.props.processingMessage}</h2>
            </Jumbotron>
          </Fragment>
        }
      </Fragment>
    )
  }
}

GridListChecked.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  selection: PropTypes.array.isRequired,
  updateSelection: PropTypes.func.isRequired,
  processingMessage: PropTypes.string.isRequired
}

export default GridListChecked