//Redux actions for diver verification modal
import { DIVER_VERIFIED } from '../../redux/action-types.js'



export const diverVerified = (verified, certs, verifiedDiverIdNumber, givenName, surname) => ({
  type: DIVER_VERIFIED,
  diverVerified: verified,
  diverCertifications: certs,
  verifiedDiverIdNumber: verifiedDiverIdNumber,
  givenName: givenName,
  surname: surname
})
