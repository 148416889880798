import React, { Component, Fragment } from 'react';
import { Grid, Row, Col, Button, Tab, Tabs } from 'react-bootstrap';
import EditPerson from '../../EditPerson/EditPerson.js';
import FindDiverUiModal from '../../FindDiverUiModal/FindDiverUiModal.js';
import ProtectedFieldsPersonEditor from './ProtectedFieldsPersonEditor.js';
import CertificationEditor from './CertificationEditor.js';
import PersonInfoPanel from '../../PersonInfoPanel/PersonInfoPanel.js';
import person from '../../../model/Person.js';
import certification from '../../../model/Certification.js';
import update from 'immutability-helper';

class PersonEdiror extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: {},
      ctl_showFindDiverByIdModal: false,
      ctl_showVerificationModal: false,
      key: 1,
    };
  }

  handleFindDiver = (visible, person = null) => {
    if (person === null) {
      this.setState({ ctl_showFindDiverByIdModal: visible });
    } else {
      this.setState({ person: person, ctl_showFindDiverByIdModal: visible });
    }
  };

  handleSelect = (key) => {
    this.setState({ key: key });
  };

  reloadPerson = async () => {
    var reload = await person.findById(this.state.person.personId);
    this.setState({ person: reload });
  };

  savePersonCallback = async (personData) => {
    try {
      var newPerson = new person(personData.data);
      await newPerson.save();
      let newState = update(this.state, {
        person: { $set: newPerson },
      });
      this.setState(newState);
      alert('Diver information Saved');
    } catch (e) {
      alert(e);
    }
  };

  saveCertificationCallback = async (certificationData) => {
    try {
      var newCert = new certification(certificationData);
      await newCert.save();
      var updatedPerson = await person.findById(this.state.person.personId);
      let newState = update(this.state, {
        person: { $set: updatedPerson },
      });
      this.setState(newState);
      alert('Certification information Saved');
    } catch (error) {
      alert(error);
    }
  };
  render() {
    return (
      <Fragment>
        <FindDiverUiModal callback={this.handleFindDiver} visible={this.state.ctl_showFindDiverByIdModal} />
        <Grid>
          <Row>
            <Col md={3}>
              <Button type="button" onClick={() => this.setState({ ctl_showFindDiverByIdModal: true })}>
                Find Diver
              </Button>
              {this.state.key === 1 ? null : <PersonInfoPanel person={this.state.person} />}
            </Col>
            <Col md={9}>
              {this.state.person && this.state.person.personId ? (
                <Fragment>
                  <Tabs id="edit-person-tabs" defaultActiveKey={1} onSelect={this.handleSelect}>
                    <Tab eventKey={1} title="Demographics">
                      <ProtectedFieldsPersonEditor person={this.state.person} savePerson={this.savePersonCallback} />
                      <EditPerson
                        person={this.state.person}
                        savePerson={this.savePersonCallback}
                        allowEmailChange={true}
                      />
                    </Tab>
                    <Tab eventKey={2} title="Certifications">
                      <CertificationEditor
                        person={this.state.person}
                        reloadPerson={this.reloadPerson}
                        saveCertification={this.saveCertificationCallback}
                      />
                    </Tab>
                  </Tabs>
                </Fragment>
              ) : null}
            </Col>
          </Row>
        </Grid>
      </Fragment>
    );
  }
}
export default PersonEdiror;
