import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

class InstructorDownloads extends Component {
  render() {
    return (
<div>
  <div className="container">
    <h1>Instructor Resources</h1>
    <p>
      <a href='https://cdn.scubaeducators.org/instructorguides/StandardsProcedures.pdf' target="_blank">Standards and Procedures</a>
    </p>
    <p>
      <a href='https://cdn.scubaeducators.org/instructorresources/PriceList.pdf' target="_blank">Instructor Price List</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorresources/PublicSafetyDiveProgram.pdf' target="_blank">Public Safety Program Guide</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorresources/WaiverReleaseForm.pdf' target="_blank">Release Form</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorresources/Medical-form.pdf' target="_blank">Medical Form</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorresources/StudentRecordPacket.pdf' target="_blank">Student Record Packet</a>
  </p>
  </div>
  <div className="container">
  <h1>Instructor Guides</h1>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/AirDiveTables.pdf' target="_blank">Air Dive Tables</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/Advanced.zip'>Advanced Open Water</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/DRAM.zip'>Recreational Dive Rescue and Accident Management</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/Divemaster-AssistantInstructor.zip'>Divemaster - Assistant Instructor</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/IceDiving.zip'>Ice Diving</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/Instructor.zip'>Instructor</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/LeadershipPrepManual.pdf' target="_blank">Leadership Prep Manual</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/Nitrox.zip'>Nitrox</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/OpenWater-Graver.zip'>Open Water - Graver</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/OpenWater-LearnScubaDiving.zip'>Open Water - Learn Scuba Diving</a>
  </p>
  <p>
    <a href='https://cdn.scubaeducators.org/instructorguides/SearchAndRecovery.zip'>Recreational Search & Recovery</a>
  </p>
  </div>  
  </div>
    );
  }
}
export default InstructorDownloads;
