import React, { Component, Fragment } from 'react'
import {Grid, Row, Col, Button, Panel, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import CertifyUi from '../../Certify/CertifyUi.js'
import CreatePerson from '../../EditPerson/CreatePerson.js'
import certificationOffering from '../../../model/CertificationOffering.js'
import certification from '../../../model/Certification.js'
import FindDiverUiModal from '../../FindDiverUiModal/FindDiverUiModal.js'
import PersonInfoPanel from '../../PersonInfoPanel/PersonInfoPanel.js'
import update from 'immutability-helper'
import uuid from 'uuid'
import _ from 'lodash'

class EnterCertificationForInstructor extends Component {
  constructor(props){
    super(props)
    this.state = {
      diver: null,
      instructor: null,
      ctl_findDiver: false,
      ctl_findInstructor: false,
      ctl_createNewPerson: false,
      certificationOfferings: []
    }
  }

  componentDidMount = async () => {
      var certOfferings = await certificationOffering.getListOfOfferings()
      this.setState({certificationOfferings: certOfferings, ctl_selectedCertList: 'active'})
  }

  handleFindDiverByIdModal = (visible, person = null) => {
    var newState = {}
    if(person === null){
      this.setState({ctl_findDiver: false, ctl_findInstructor: false})
    } else {
      if (this.state.ctl_findDiver === true && this.state.ctl_findInstructor === false){
        newState = update(this.state, {diver: {$set: person}, ctl_findDiver: {$set: false}, ctl_findInstructor: {$set: false}})
        this.setState(newState)
      }
      if (this.state.ctl_findDiver === false && this.state.ctl_findInstructor === true){
        newState = update(this.state, {instructor: {$set: person}, ctl_findDiver: {$set: false}, ctl_findInstructor: {$set: false}})
        this.setState(newState)
      }
    }
  }

  selectCertList = async (event) => {
    var certOfferings
    var selectedValue = event.target.value
    console.log(selectedValue)
    switch(selectedValue){
      case 'active':
        certOfferings = await certificationOffering.getListOfOfferings()
        this.setState({ctl_selectedCertList: selectedValue, certificationOfferings: certOfferings})
        break;
      
      case 'all':
        certOfferings = await certificationOffering.getAllOfferings()
        this.setState({ctl_selectedCertList: selectedValue, certificationOfferings: certOfferings})
        break;

      default:
        certOfferings = await certificationOffering.getListOfOfferings()
        this.setState({ctl_selectedCertList: 'active', certificationOfferings: certOfferings})
        break;
    }

  }

  handleCreatePerson = (person) => {
    this.setState({
      diver: person,
      ctl_createNewPerson: false
    })
  }
  handleCertify = async (certDate, certName, cardReturn) => {
    var certInfo = _.find(this.state.certificationOfferings, {'name': certName})
    var certificationData = {
      certId: uuid(),
      certName: certName,
      certLevel: certInfo.level,
      certDate: certDate.format(),
      instructorId: this.state.instructor.personId,
      instructorFirstName: this.state.instructor.givenName,
      instructorLastName: this.state.instructor.surname
    }
    var cert = new certification(certificationData)
    await this.state.diver.certify(cert, cardReturn)
    alert('Certification Submitted')
    this.setState({diver: null})

  }
  render(){

    return(
      <Fragment>
        <FindDiverUiModal callback={this.handleFindDiverByIdModal} visible={this.state.ctl_findDiver || this.state.ctl_findInstructor} />
        <Grid fluid={true}>
          <Row>
            <Col md={4}>
              {
                this.state.instructor !== null ? <PersonInfoPanel person={this.state.instructor} msg='Instructor' /> : null
              }
              <Button type='button' onClick={() => this.setState({ctl_findInstructor: true, ctl_findDiver: false})}>Select Instructor</Button>
            </Col>
            <Col md={4}>
              {
                this.state.diver !== null ? <PersonInfoPanel person={this.state.diver} msg='Diver' showPicture={true}/> : null
              }
              <Button type='button' onClick={() => this.setState({ctl_findInstructor: false, ctl_findDiver: true, ctl_createNewPerson: false})}>Select Diver</Button>
              <Button type='button' onClick={() => this.setState({ctl_findInstructor: false, ctl_findDiver: false, ctl_createNewPerson: true})}>Create New Diver</Button>
            </Col>
            <Col md={4}>

            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {
                this.state.diver !== null && this.state.instructor !== null && this.state.certificationOfferings.length > 0 && this.state.ctl_createNewPerson === false
                ?
                <Fragment>
                  <Panel>
                    <Panel.Heading>Certification List</Panel.Heading>
                    <Panel.Body>
                      <FormGroup controlId='selectedCertList'>
                        <FormControl componentClass='select' value={this.state.ctl_selectedCertList} onChange={this.selectCertList.bind(this)}>
                          <option value='active'>Active Certifications</option>
                          <option value='all'>All Certifications</option>
                        </FormControl>
                      </FormGroup>
                    </Panel.Body>
                  </Panel>
                  <CertifyUi callback={this.handleCertify} allowedCertifications={this.state.certificationOfferings} person={this.state.diver}/>
                </Fragment>
                :
                null
              }
              {
                this.state.ctl_createNewPerson === true
                ?
                <CreatePerson savePersonCallback={this.handleCreatePerson} />
                :
                null
              }
            </Col>
          </Row>
        </Grid>
      </Fragment>
    )
  }
}
export default EnterCertificationForInstructor
