import React, { Component, Fragment } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import PersonCertificationViewer from './PersonCertificationViewer.js'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
    return {
        person: state.person
    }
}

class MyCertifications extends Component {
    render(){
        return(
            <Grid fluid={true}>
                <Row>
                    <Col md={12}>
                        {
                            this.props.person && this.props.person.certifications
                            ?
                             <PersonCertificationViewer {...this.props.person}/>
                             :
                             null
                        }
                    </Col>
                </Row>                
            </Grid>
        )
    }
}
export default connect(mapStateToProps)(MyCertifications)