import {
  HANDLE_AUTH_STATE_CHANGE,
  CTL_SHOW_VERIFICATION_PANEL,
  DIVER_VERIFIED, GLOBAL_SET_PERSON,
  GLOBAL_REFRESH_AUTHDATA,
  SET_ALLOWED_CERTIFICATIONS
} from './action-types.js';
import { initialState } from './initialState.js'
import { handleAuthStateChange } from '../components/withAuthenticator/reducers.js'
import { ctl_showVerificationPanel, diverVerified } from '../components/VerifyDiverModal/reducers.js'
import { globalSetPerson, refreshAuthData, setAllowedCertifications } from './global/reducers.js'


const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_AUTH_STATE_CHANGE:
      return handleAuthStateChange(state, action)
    case CTL_SHOW_VERIFICATION_PANEL:
      return ctl_showVerificationPanel(state, action)
    case DIVER_VERIFIED:
      return diverVerified(state, action)
    case GLOBAL_SET_PERSON:
      return globalSetPerson(state, action)
    case GLOBAL_REFRESH_AUTHDATA:
      return refreshAuthData(state, action)
    case SET_ALLOWED_CERTIFICATIONS:
      return setAllowedCertifications(state, action)
    default:
      return state
  }
}

export default rootReducer
