import React, { Component, Fragment } from "react";
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import SecuredApp from './SecuredApp.js';
import MainMenu from './components/MainMenu/MainMenu.js';
import VerifyDiverPage from './components/VerifyDiverPage/VerifyDiverPage.js'
import "./App.css";

const mapStateToProps = (state) => {
  return {
    authState: state.authState,
    authData: state.authData
  }
}
// const mapDispatchToProps = (dispatch) => {
//   return{
//     authenticate: (authState) => {dispatch(authenticate(authState))}
//   }
// }


class App extends Component {

  render() {
    return (
      <Fragment>
        <MainMenu />
        <Route path='/verify' exact component={VerifyDiverPage} />
        {
          this.props.location.pathname !== '/verify'
          ?
          <SecuredApp />
          :
          null
        }
      </Fragment>
    );
  }
}

//export default connect(mapStateToProps, mapDispatchToProps)(App);
//export default withAuthenticator(App, {includeGreetings: true})
export default withRouter(connect(mapStateToProps)(App))
