import _ from 'lodash';

export const checkRole = (role, groups) => {
  if(_.find(groups, function(o) { return o === role; }) === role){
    return true
  } else {
    return false
  }
}

export const roles = {
  app_instructors_role: 'app_instructors_role',
  app_admin_region_role: 'app_admin_region_role',
  app_admin_global_role: 'app_admin_global_role'
}
