import certification from '../Certification.js';
import moment from 'moment';
import _ from 'lodash';
export const certBatchData = async function (batch) {
  //responsible for returning an array of objects used to render the Certification Batch Data CSV dile for download.
  //takes a fully populated certification batch for input
  var data = [];

  for (var i = 0; i < batch.certifications.length; i++) {
    let cert = await certification.findById(batch.certifications[i]);
    console.log(cert);
    console.log('name');
    console.log(cert.certName);
    let student = await cert.getThisDiver();
    let instructor = await cert.getThisInstructor();
    console.log('===Data view function===');
    var item = {
      certId: cert.certId,
      batchId: batch.batchId,
      batchName: batch.batchName,
      diverRecordId: student.personId,
      diverGivenName: student.givenName,
      diverSurname: student.surname,
      diverIdNumber: student.diverIdNumber,
      email: student.email || '',
      phone: student.phone || '',
      address1: student.address1 || '',
      address2: student.address2 || '',
      city: student.city || '',
      state: student.state || '',
      postalCode: student.postalCode || '',
      country: student.country || '',
      certDate: moment(cert.certDate).format('MM/DD/YYYY'),
      instructorGivenName: instructor.givenName,
      instructorSurname: instructor.surname,
      instructorIdNumber: instructor.diverIdNumber,
      birthdate: moment(student.birthdate).format('MM/DD/YYYY'),
      certName: cert.certName,
      photoURL: 'http://diverphotos-sei-portal.s3-website-us-east-1.amazonaws.com/' + student.personId,
    };
    if (cert.cardReturn === 'student') {
      item.shippingName = `${student.givenName} ${student.surname}`
      item.shippingAddress1 = student.address1;
      item.shippingAddress2 = student.address2;
      item.shippingCity = student.city;
      item.shippingState = student.state;
      item.shippingPostalCode = student.postalCode;
      item.shippingCountry = student.country;
    } else {
      item.shippingName = `${instructor.givenName} ${instructor.surname}`
      item.shippingAddress1 = instructor.address1;
      item.shippingAddress2 = instructor.address2;
      item.shippingCity = instructor.city;
      item.shippingState = instructor.state;
      item.shippingPostalCode = instructor.postalCode;
      item.shippingCountry = instructor.country;
    }

    if (cert.expires === true) {
      item.expires = 'Yes';
      item.expirationDate = cert.expirationDate;
    } else {
      item.expires = 'No';
      item.expirationDate = '';
    }
    console.log(item);
    data.push(item);
  }
  return data;
};

export const headers = [
  'certId',
  'batchId',
  'batchName',
  'diverRecordId',
  'diverGivenName',
  'diverSurname',
  'diverIdNumber',
  'email',
  'phone',
  'address1',
  'address2',
  'city',
  'state',
  'postalCode',
  'country',
  'certDate',
  'birthdate',
  'certName',
  'photoURL',
  'instructorGivenName',
  'instructorSurname',
  'instructorIdNumber',
  'shippingName',
  'shippingAddress1',
  'shippingAddress2',
  'shippingCity',
  'shippingState',
  'shippingPostalCode',
  'shippingCountry',
  'expires',
  'expirationDate',
];
