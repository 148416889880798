import React, { Component, Fragment } from 'react'
import { Jumbotron } from 'react-bootstrap'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class GridListReadOnly extends Component {

  render() {
    return (
      <Fragment>
        {
          this.props.data.length || this.props.data.length > 0
            ?
            <ReactTable
              data={this.props.data}
              columns={this.props.columns}
              defaultPageSize={10}
              filterable
              sortable
            />
            :
            <Fragment>
              <Jumbotron>
                <h2>{this.props.processingMessage}</h2>
              </Jumbotron>
            </Fragment>
        }
      </Fragment>
    )
  }
}

GridListReadOnly.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  processingMessage: PropTypes.string.isRequired
}

export default GridListReadOnly