import React, { Component, Fragment } from 'react'
import {Panel, Grid, Row, Col, Button, ControlLabel } from 'react-bootstrap'
import { connect } from 'react-redux'
import VerifyDiverModal from '../VerifyDiverModal/VerifyDiverModal.js'
import { ctl_showVerificationPanel } from '../../redux/control-actions.js'
import _ from 'lodash'

const mapStateToProps = (state) => {
  return {
    verification: state.verification
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showVerification: (visible) => {dispatch(ctl_showVerificationPanel(visible))}
  }
}

class VerifyDiverPage extends Component {

  verifyCallback(){
    return
  }

  render(){
    return(
      <Fragment>
        <VerifyDiverModal verifyCallback={this.verifyCallback}/>
        <Grid>
          <Row>
            <Col md={12}>
              <h2>SEI Diver Verification</h2>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <Button type='button' onClick={() => this.props.showVerification(true)}>Find Diver</Button>
            </Col>
            <Col md={10}>
              {
                this.props.verification && this.props.verification.diverVerified === true
                ?
                <VerificationResults verification={this.props.verification} />
                :
                null
              }
            </Col>
          </Row>
        </Grid>
      </Fragment>
    )
  }
}

class VerificationResults extends Component {
  mapCertsToList = () => {
    var sorted = _.orderBy(this.props.verification.diverCertifications, ['certLevel'],['desc'])
    return sorted.map((c, idx) => {
      return (<Row key={idx}><ControlLabel>{c.certName}</ControlLabel></Row>)
    })
  }

  render(){
    return (
      <Panel>
        <Row><ControlLabel>{'Diver ID: ' + this.props.verification.verifiedDiverIdNumber}</ControlLabel></Row>
        <Row><ControlLabel>{'Name: ' + this.props.verification.givenName + ' ' + this.props.verification.surname}</ControlLabel></Row>
        <Row><ControlLabel>Certifications:</ControlLabel></Row>
        {this.mapCertsToList()}
      </Panel>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyDiverPage)