import React, { Component, Fragment } from 'react'
import { Jumbotron } from 'react-bootstrap'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css'

const CheckboxTable = checkboxHOC(ReactTable)

class GridListRadio extends Component {
  constructor(props){
    super(props)
    this.state = {
      selectAll: false
    }
  }

  toggleSelection = (key, shift, row) => {
    //finally store new selection to state
    this.props.updateSelection(key)
    // this.setState({ selection })
  }

  isSelected = key => {
    return this.props.selection === key;
  };


  render() {
    const { toggleSelection, isSelected } = this;
    const { selectAll } = this.state;
    const { data, columns } = this.props

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      selectType: "radio",
      // getTrProps: (s, r) => {
      //   // someone asked for an example of a background color change
      //   // here it is...
      //   const selected = this.isSelected(r.original._id)
      //   return {
      //     style: {
      //       backgroundColor: selected ? "lightgreen" : "inherit"
      //       // color: selected ? 'white' : 'inherit',
      //     }
      //   }
      // }
    }

    return (
      <Fragment>
        {
          data.length || data.length > 0
          ?
          <CheckboxTable
            ref={r => (this.checkboxTable = r)}
            data={data}
            columns={columns}
            defaultPageSize={10}
            className='-striped -highlight'
            filterable
            sortable
            {...checkboxProps}
          />
          :
          <Fragment>
            <Jumbotron>
              <h2>{this.props.processingMessage}</h2>
            </Jumbotron>
          </Fragment>
        }
      </Fragment>
    )
  }
}

GridListRadio.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  selection: PropTypes.number.isRequired,
  updateSelection: PropTypes.func.isRequired,
  processingMessage: PropTypes.string.isRequired
}

export default GridListRadio