import React from 'react'
import { Grid, Row, ControlLabel } from 'react-bootstrap'
import PropTypes from 'prop-types'
import moment from 'moment'

const InsuranceViewer = (props) => {
    return(
        <Grid fluid={true}>
            <Row><ControlLabel>{'Insurance Agency: ' + props.insuranceAgency}</ControlLabel></Row>
            <Row><ControlLabel>{'Insurance Policy Number: ' + props.insurancePolicyNumber}</ControlLabel></Row>
            <Row><ControlLabel>{'Insurance Policy Expiration Date: ' + moment(props.insuranceExpirationDate).format('MM/DD/YYYY')}</ControlLabel></Row>
        </Grid>
    )
}

InsuranceViewer.propTypes = {
    insuranceAgency: PropTypes.string.isRequired,
    insurancePolicyNumber: PropTypes.string.isRequired,
    insuranceExpirationDate: PropTypes.string.isRequired
}
export default InsuranceViewer