import React from 'react';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

export default ({ id, label, showError, errorText, ...props }) => {
  var showBlock = showError === true && errorText !== "";
  return(
    <FormGroup controlId={id} bsSize="large" validationState={showBlock ? "error" : null}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl
        {...props}
      />
      {showBlock
      ?
      <HelpBlock>{errorText}</HelpBlock>
      :
      null
      }
    </FormGroup>
  );
}
