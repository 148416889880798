//Returns a bootstrap row with the date fields
import React, { Component } from 'react'
import ValidatedFormGroup from '../../ValidatedFormGroup.js'
import { Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import PropTypes from 'prop-types'

const months = [
	{name: '01 - January', value: '01'},
	{name: '02 - February', value: '02'},
	{name: '03 - March', value: '03'},
	{name: '04 - April', value: '04'},
	{name: '05 - May', value: '05'},
	{name: '06 - June', value: '06'},
	{name: '07 - July', value: '07'},
	{name: '08 - August', value: '08'},
	{name: '09 - September', value: '09'},
	{name: '10 - October', value: '10'},
	{name: '11 - November', value: '11'},
	{name: '12 - December', value: '12'}
]

class ValidatedDateFields extends Component {

	constructor(props){
		super(props)
		this.state = {
			dayError: '',
			yearError: ''
		}
	}

	getErrorFor = (field, errors) => {
		if(errors[field]) {
			return errors[field]
		}
	  if(field === this.props.id_day && this.state.dayError !== ''){
			return this.state.dayError
		}
		if(field === this.props.id_year && this.state.yearError !== ''){
			return this.state.yearError
		}
		return ''
	}

	validatedChangeHandler = (evt) => {
		if (evt.target.id === this.props.id_day && evt.target.value > 31){
			this.setState({dayError: 'Day Invalid'})
		} else {
			this.setState({dayError: ''})
		}

		if ((evt.target.id === this.props.id_year && parseInt(evt.target.value, 10) < 1900) || parseInt(evt.target.value, 10) > 9999){
			this.setState({yearError: 'Year Invalid'})
		} else {
			this.setState({yearError: ''})
		}

		this.props.handleChange(evt)
	}

	render(){
    var {month, day, year, errors} = this.props
	  var options = months.map((o, idx) =>{
	    return(<option key={idx+1} value={o.value}>{o.name}</option>)
	  })

	  return(
	    <Row>
	      <Col xs={4} sm={4} md={4}>
	    		<FormGroup controlId={this.props.id_month}>
	    			<ControlLabel>Month</ControlLabel>
	    			<FormControl componentClass='select' placeholder='Select Month...' value={month || ''} onChange={(evt) => this.validatedChangeHandler(evt)}>
	            <option key={0} value={0}>Select Month...</option>
	            {options}
	    			</FormControl>
	        </FormGroup>
	      </Col>
	      <Col xs={4} sm={4} md={4}>
	        <ValidatedFormGroup
	          id={this.props.id_day}
	          label='Day'
	          showError={true}
	          errorText={this.getErrorFor(this.props.id_day, errors)}
	          type='text'
	          value={day}
	          onChange={(evt) => this.validatedChangeHandler(evt)}
	        />
	      </Col>
	      <Col xs={4} sm={4} md={4}>
	        <ValidatedFormGroup
	          id={this.props.id_year}
	          label='Year'
	          showError={true}
	          errorText={this.getErrorFor(this.props.id_year, errors)}
	          type='text'
	          value={year}
	          onChange={(evt) => this.validatedChangeHandler(evt)}
	        />
	      </Col>
	    </Row>
	  )
	}
}

ValidatedDateFields.propTypes = {
  id_month: PropTypes.string.isRequired,
  id_day: PropTypes.string.isRequired,
  id_year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired

}

export default ValidatedDateFields