import { validator } from '../../../libs/validation/validator.js'
import { required, isNumeric } from '../../../libs/validation/rules.js'

export const fieldValidations = [
  // validator("phone", "Phone Number", required),
  validator("month", "Month", required, isNumeric),
  validator("day", "Day", required, isNumeric),
  validator("year", "Year", required, isNumeric),
  validator("givenName", "First Name", required),
  validator("surname", "Last Name", required),
  validator("diverIdNumber", "Diver ID Number", required, isNumeric)
]